
export default {
  props: {
    color: {
      required: true,
      type: String,
    },
    size: {
      default: 12,
      type: Number,
    },
  },
};
