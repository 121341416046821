
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import useImageSettingsMobile from '@/components/Editor/ImageSettings/ImageSettingsMobile/ImageSettingsMobile';
import Resolution from '@/components/Editor/Resolution/Resolution.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import AlignHorizontalCenterIcon from '@/components/icons/AlignHorizontalCenterIcon.vue';
import ChevronIcon from '@/components/icons/ChevronIcon.vue';
import RotationIcon from '@/components/icons/RotationIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';

export default {
  components: {
    AlignHorizontalCenterIcon,
    Alignments,
    BaseIcon,
    ChevronIcon,
    Resolution,
    Rotation,
    RotationIcon,
    TrashcanIcon,
  },
  props: {
    activeImage: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
      default: () => defaultLockObject,
    },
  },
  setup() {
    return useImageSettingsMobile();
  },
};
