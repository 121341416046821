import { OBJECT_CUSTOM_ATTRIBUTES } from '@/components/Editor/constants/customConfigs';
import { FabricEvent, FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import { TRIGGER_SET_ACTIVE_OBJECT } from '@/store/Editor/constants';

export const saveActiveObjectInStore = (
  object: FabricObject,
  isUpdateHistory: boolean,
): void => {
  store.dispatch(
    TRIGGER_SET_ACTIVE_OBJECT,
    { isUpdateHistory, object },
  );
};

export const discardActiveObjectInStore = (
  { e, target }: FabricEvent,
): void => {
  if (!target?.deleted) {
    saveActiveObjectInStore(null, e?.isTrusted);
  }
};

export const saveUpdatedActiveObjectInStore = (
  { e, target }: FabricEvent,
): void => {
  saveActiveObjectInStore(
    target.toObject(OBJECT_CUSTOM_ATTRIBUTES),
    e?.isTrusted,
  );
};

export const saveActiveObjectInHistory = (
  { target }: FabricEvent,
) => {
  saveActiveObjectInStore(
    target.toObject(OBJECT_CUSTOM_ATTRIBUTES),
    true,
  );
};
