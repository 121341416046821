
import HighlightedName from '../HighlightedName/HighlightedName.vue';
export default {
  components: {
    HighlightedName,
  },
  props: {
    foundProducts: Object,
    isSearchOpen: {
      required: false,
      type: Boolean,
    },
    isShowSearchList: {
      required: false,
      type: Boolean,
    },
    search: {
      required: true,
      type: String,
    },
  },
};
