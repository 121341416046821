
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';  
import useConfirmRemoveTemplateModal from './ConfirmRemoveTemplateModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    isOpen: Boolean,
    template: Object,
  },
  setup(props, context) {
    return useConfirmRemoveTemplateModal(props, context);
  },
};
