import { fabric } from 'fabric';
import {
  FabricCanvas,
  FabricEvent,
  FabricPoint,
  FabricText,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_IS_MOBILE } from '@/store/Editor/constants';
import { ToggleTypes } from '@/types';
import { isAndroid } from '@/utils/isAndroid';

const defineStartPoint = (canvas: FabricCanvas): FabricPoint => {
  const vpt = canvas.viewportTransform;
  return { x: vpt[4], y: vpt[5] };
};

const defineFinalZoom = (
  halfCanvasWidth: number,
  halfCanvasHeight: number,
  textElement: FabricText,
): number => {
  const { height: textHeight, width: textWidth } = textElement;
  const deltaZoomX = halfCanvasWidth / textWidth;
  const deltaZoomY = halfCanvasHeight / textHeight;

  return +Math.min(deltaZoomX, deltaZoomY).toFixed(2);
};

const definePointToZoom = (
  halfCanvasWidth: number,
  halfCanvasHeight: number,
  textElement: FabricText,
  finalZoom: number,
): FabricPoint => {
  const {
    x: textCenterX,
    y: textCenterY,
  } = textElement.getCenterPoint();
  
  return {
    x: (halfCanvasWidth - textCenterX * finalZoom),
    y: (halfCanvasHeight - textCenterY * finalZoom),
  };
};

const setTextElementAngle = (
  textElement: fabric.Text,
  angle: number,
): void => {
  textElement.angle = angle;
  textElement.setCoords();
};

const setViewportForAndroid = (
  { isDefault }: { isDefault: boolean },
): void => {
  if (isAndroid()) {
    const windowHeight = document.body.offsetHeight;
    const windowWidth = document.body.offsetWidth;
    const viewport = document.querySelector('meta[name=viewport]');
    viewport?.setAttribute(
      'content',
      isDefault
        ? 'width=device-width,initial-scale=1.0,maximum-scale=1,user-scalable=no'
        : `height=${windowHeight},width=${windowWidth},initial-scale=1.0`,
    );
  }
};

const setViewportTransformWithAnimation = (
  canvas: FabricCanvas,
  { x: startPointX, y: startPointY }: FabricPoint,
  { x: endPointX, y: endPointY }: FabricPoint,
  startZoom: number,
  endZoom: number,
): void => {
  let deltaPointX = startPointX;
  let deltaPointY = startPointY;

  fabric.util.animate({
    duration: 500,
    endValue: endPointX,
    onChange: (deltaX: number) => deltaPointX = deltaX,
    startValue: startPointX,
  });

  fabric.util.animate({
    duration: 500,
    endValue: endPointY,
    onChange: (deltaY: number) => deltaPointY = deltaY,
    startValue: startPointY,
  });

  fabric.util.animate({
    duration: 500,
    endValue: endZoom,
    onChange: (deltaZoom: number) => {
      canvas.setViewportTransform([
        deltaZoom,
        0,
        0,
        deltaZoom,
        deltaPointX,
        deltaPointY,
      ]);
    },
    startValue: startZoom,
  });
};

export const zoomToTextOnInputMobile = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  const isMobile = store.getters[GET_IS_MOBILE];
  const halfCanvasWidth = canvas.getWidth() / 2;
  const halfCanvasHeight = canvas.getHeight() / 2;

  let initialAngle = 0;
  let initialZoom = canvas.getZoom();
  let finalZoom = initialZoom;
  let startPoint = defineStartPoint(canvas);
  let pointToZoom = startPoint;
  
  if (isMobile) {
    canvas[toggle](
      'text:editing:entered',
      ({ target: textElement }: FabricEvent): void => {
        initialAngle = textElement.angle;
        initialZoom = canvas.getZoom();
        finalZoom = defineFinalZoom(
          halfCanvasWidth,
          halfCanvasHeight,
          textElement,
        );
        pointToZoom = definePointToZoom(
          halfCanvasWidth,
          halfCanvasHeight,
          textElement,
          finalZoom,
        );
        startPoint = defineStartPoint(canvas);

        setTextElementAngle(textElement, 0);
        setViewportForAndroid({ isDefault: false });
        setViewportTransformWithAnimation(
          canvas,
          startPoint,
          pointToZoom,
          initialZoom,
          finalZoom,
        );
      },
    );

    canvas[toggle]('text:editing:exited', ({ target: textElement }): void => {
      setViewportTransformWithAnimation(
        canvas,
        pointToZoom,
        startPoint,
        finalZoom,
        initialZoom,
      );
      setTextElementAngle(textElement, initialAngle);
      setViewportForAndroid({ isDefault: true });
    });
  }
};
