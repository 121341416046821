
import BaseTable from '@/components/Common/BaseTable/BaseTable.vue';
import FileIcon from '@/components/icons/FileIcon.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import OrderInfoCard from '../components/OrderInfoCard/OrderInfoCard.vue';
import { useSingleOrder } from './SingleOrder';
export default {
  components: {
    MainLayout,
    FileIcon,
    OrderInfoCard,
    BaseTable,
  },
  setup() {
    return useSingleOrder();
  },
};
