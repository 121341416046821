
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';

export default {
  components: { BaseButton, BaseIcon },
  props: {
    horizontalAlignments: {
      required: true,
      type: Array,
    },
    verticalAlignments: {
      required: true,
      type: Array,
    },
  },
  emits: ['triggerAlignment'],
};
