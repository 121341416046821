
import TextField from '@/components/Common/TextField/TextField.vue';
import TemplateDesignCard from './TemplateDesignCard/TemplateDesignCard.vue';
import { useTemplateDesignSidebar } from './TemplateDesignSidebar';

export default {
  components: {
    TextField,
    TemplateDesignCard,
  },
  setup() {
    return useTemplateDesignSidebar();
  },
};
