
import { PropType } from 'vue';

import { OrderStatus } from '@/store/Orders/types';
import { useOrderStatusChip } from './OrderStatusChip';

export default {
  props: {
    status: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
  },
  setup(props) {
    return useOrderStatusChip(props);
  },
};
