import { setObjectsLayerName } from '@/components/Editor/helpers/layers/layerName';
import { Size } from '@/components/Editor/types/editor';
import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import { CanvasData } from '@/store/Editor/types';
import { createFabricImage } from '../imageObject/newFabricImage';
import { setCustomPropsToImage } from '../imageObject/setCustomPropsToImage';
import { isFabricImage, isFabricText } from '../objectModifiers/checkObjectType';
import { createFabricText } from '../textObject/newFabricText';
import { setCustomPropsToText } from '../textObject/setCustomPropsToText';

export const sortElementsByLayerNumber = (
  canvasData: CanvasData,
): CanvasData => {
  return canvasData
    .sort((a: FabricObject, b: FabricObject): number => {
      return a.layerNumber - b.layerNumber;
    });
};

export const restoreElementsOnCanvas = async (
  canvas: FabricCanvas,
  canvasData: any,
  { height, width }: Size,
): Promise<void> => {
  const sortedCanvasData = sortElementsByLayerNumber(canvasData);

  const objects = await Promise.all(
    sortedCanvasData.map(async (
      object: FabricObject,
    ): Promise<FabricObject> => {
      switch (true) {
      case isFabricImage(object): {
        const fabricImage = await createFabricImage({
          canvasSize: { height, width },
          imageSettings: object,
          applyCanvasZoom: false,
          isCanvasRestored: true,
        });
        setCustomPropsToImage(canvas, fabricImage, false);
        return fabricImage;
      }
      case isFabricText(object): {
        const fabricText = createFabricText(object);
        setCustomPropsToText(fabricText);
        return fabricText;
      }
      default: break;
      }
    }),
  );

  objects.forEach((object: FabricObject): void => {
    canvas.add(object);
  });
  setObjectsLayerName(objects);
};
