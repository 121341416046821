
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useThemeSelectorMobile from './ThemeSelectorMobile';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    currentThemeId: {
      required: false,
      type: [ String, Number ],
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    themes: {
      required: false,
      type: Array,
    },
  },
  setup(props, context) {
    return useThemeSelectorMobile(props, context);
  },
};
