
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import useTemplatesHeader from './TemplatesHeader';

export default {
  components: {
    BaseSelect,
  },
  props: {
    collection: {
      required: false,
      type: Object,
    },
    sort: {
      required: true,
      type: String,
    },
    templates: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    return useTemplatesHeader(props);
  },
};
