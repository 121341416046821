
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useUploadPage from './UploadPage';

export default {
  components: {
    BaseButton,
    BaseSelect,
    MainLayout,
    TextField,
  },
  setup() {
    return useUploadPage();
  },
};
