import { FabricEvent, FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_ACTIVE_CANVAS } from '@/store/Editor/constants';

export const deleteFabricObject = (_: any, { target }: FabricEvent): void => {
  const activeCanvas = store.getters[GET_ACTIVE_CANVAS];
  const fabricObject = activeCanvas
    .getObjects()
    .find((object: FabricObject): boolean => {
      return object.id === target.id;
    });
  if (!fabricObject) return;
  fabricObject.deleted = true;
  activeCanvas.remove(fabricObject).renderAll();
};
