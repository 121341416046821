
import useHighlightedName from './HighlightedName';

export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    search: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return useHighlightedName(props);
  },
};
