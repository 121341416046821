
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
import SpinnerLoader from '@/components/SpinnerLoader/SpinnerLoader.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useTemplates from './Templates';
import TemplatesHeader from './TemplatesHeader/TemplatesHeader.vue';
import TemplatesList from './TemplatesList/TemplatesList.vue';
import TemplatesNotExists from './TemplatesNotExists/TemplatesNotExists.vue';

export default {
  components: {
    BaseButton,
    MainLayout,
    SkeletonLoader,
    SpinnerLoader,
    TemplatesHeader,
    TemplatesList,
    TemplatesNotExists,
  },
  setup() {
    return useTemplates();
  },
};
