import { ActionContext } from 'vuex';
import { OBJECT_CUSTOM_ATTRIBUTES } from '@/components/Editor/constants/customConfigs';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import {
  ACTIVATE_EDITOR,
  CLEAR_CANVAS_HISTORY,
  DISABLE_SETTINGS_ON_IMAGE_LOADING,
  LOAD_GOOGLE_FONTS_LIST,
  SET_ACTIVE_CANVAS,
  SET_BACKGROUND_COLOR,
  SET_FABRIC_OBJECTS,
  SET_INITIAL_EDITOR_DATA,
  SET_IS_DESIGN_MODE,
  SET_IS_EDITOR_ACTIVE,
  SET_IS_IMAGE_LOADING,
  SET_IS_SETTINS_DISABLED,
  SET_IS_UNDO_REDO_HIDDEN,
} from '../constants';
import { EditorState } from '../types';

export const editorConfigsActions = {
  [SET_INITIAL_EDITOR_DATA]: (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    canvas: FabricCanvas,
  ): void => {
    commit(CLEAR_CANVAS_HISTORY);
    const objects = canvas
      .getObjects()
      .map((object: FabricObject): FabricObject => {
        if (object.id === BACKGROUND_OBJECT_ID) {
          commit(SET_BACKGROUND_COLOR, object.fill);
        }
        return object.toObject(OBJECT_CUSTOM_ATTRIBUTES);
      });
    commit(SET_FABRIC_OBJECTS, objects);
    commit(SET_ACTIVE_CANVAS, canvas);
    if (!state.fontsConfigs.googleFontsList.length) {
      dispatch(LOAD_GOOGLE_FONTS_LIST);
    }
  },
  [ACTIVATE_EDITOR]: (
    { commit }: ActionContext<EditorState, any>,
    flag: boolean,
  ): void => {
    commit(SET_IS_EDITOR_ACTIVE, flag);
    commit(SET_IS_UNDO_REDO_HIDDEN, !flag);
  },
  [DISABLE_SETTINGS_ON_IMAGE_LOADING]: (
    { commit, state }: ActionContext<EditorState, any>,
    flag: boolean,
  ) => {
    if (!state.editorConfigs.isApplyingDesign) {
      commit(SET_IS_IMAGE_LOADING, flag);
      commit(SET_IS_SETTINS_DISABLED, flag);
      commit(SET_IS_UNDO_REDO_HIDDEN, flag);
    }
  },
  [SET_IS_DESIGN_MODE]: (
    { commit }: ActionContext<EditorState, any>,
    { isActive }: { isActive: boolean },
  ): void => {
    commit(SET_IS_DESIGN_MODE, isActive);
  },
};
