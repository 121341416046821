
import { SkeletonTypes } from '@/types';
import useSkeletonLoader from './SkeletonLoader';
import CardsSkeleton from './Skeletons/CardsSkeleton/CardsSkeleton.vue';

export default {
  components: {
    CardsSkeleton,
  },
  props: {
    cardsCount: {
      default: 8,
      required: false,
      type: Number,
    },
    skeletonType: {
      default: SkeletonTypes.cards,
      required: true,
      type: String,
    },
  },
  setup(props) {
    return useSkeletonLoader(props);
  },
};
