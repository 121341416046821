
import ColorPickerPipette from '../ColorPickerPipette/ColorPickerPipette.vue';

export default {
  components: {
    ColorPickerPipette,
  },
  props: {
    color: {
      required: true,
      type: String,
    },
    isPickerActive: {
      required: true,
      type: Boolean,
    },
    isOverlayActive: {
      type: Boolean,
      default: false,
    },
  },
};
