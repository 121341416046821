
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import TextAlignments from '@/components/Editor/TextAlignments/TextAlignments.vue';
import TextFontSize from '@/components/Editor/TextFrontSize/TextFontSize.vue';
import { ColorPickerType } from '@/components/Editor/types/editor';
import { secondaryViolet } from '@/constants/colors';
import { SET_IS_FONT_SELECT_OPEN } from '@/store/Editor/constants';

export default {
  components: {
    Alignments,
    BaseButton,
    ColorPicker,
    Rotation,
    TextAlignments,
    TextFontSize,
  },
  props: {
    font: {
      required: true,
      type: String,
    },
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isIframe: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
    },
  },
  setup() {
    return {
      ColorPickerType,
      secondaryViolet,
      SET_IS_FONT_SELECT_OPEN,
    };
  },
};
