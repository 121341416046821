
import useHeader from './EditorHeader';
import EditorHeaderDesktop from './EditorHeaderDesktop/EditorHeaderDesktop.vue';
import EditorHeaderMobile from './EditorHeaderMobile/EditorHeaderMobile.vue';

export default {
  components: {
    EditorHeaderDesktop,
    EditorHeaderMobile,
  },
  props: {
    isPublic: {
      required: true,
      type: Boolean,
    },
    isScanLoading: {
      required: true,
      type: Boolean,
    },
    isShopifyLoading: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useHeader(props, context);
  },
};
