
import useAuthWithSocial from './AuthWithSocial';

export default {
  props: {
    authWith: {
      required: true,
      type: String,
    },
    storeConnectionToken:{
      type: String,
    },
  },
  setup(props) {
    return useAuthWithSocial(props);
  },
};
