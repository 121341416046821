
import useViewSwitcher from '@/components/Editor/ViewSwitcher/ViewSwitcher';

export default {
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useViewSwitcher();
  },
};
