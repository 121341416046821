
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { isMobile } from '@/utils/Mobile';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return {
      isMobile,
    };
  },
};
