
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import CollectionCard from './CollectionCard/CollectionCard.vue';
import useCollectionsList from './CollectionsList';
import ConfirmRemoveCollectionModal from './ConfirmRemoveCollectionModal/ConfirmRemoveCollectionModal.vue';

export default {
  components: {
    BaseButton,
    BaseSelect,
    CollectionCard,
    ConfirmRemoveCollectionModal,
  },
  props: {
    collections: Array,
    collectionType: String,
    isEditingRights: Boolean,
    isLoading: Boolean,
    sort: String,
  },
  setup(props, context) {
    return useCollectionsList(props, context);
  },
};
