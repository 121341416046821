
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { useUndoRedoIcons } from '@/components/Editor/UndoRedoIcons/UndoRedoIcons';
import RedoIcon from '@/components/icons/RedoIcon.vue';
import UndoIcon from '@/components/icons/UndoIcon.vue';

export default {
  components: {
    BaseButton,
    BaseIcon,
    RedoIcon,
    UndoIcon,
  },
  setup() {
    return useUndoRedoIcons();
  },
};
