
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import useProfileMenu from './ProfileMenu';

export default {
  components: {
    BaseMenu,
  },
  setup(_, context) {
    return useProfileMenu(context);
  },
};
