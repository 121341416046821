import { ActionContext } from 'vuex';
import {
  getValidatedObjectChanges,
} from '@/components/Editor/helpers/fabric/objectModifiers/validateObjectProps';
import { FabricObject } from '@/components/Editor/types/fabric';
import {
  CLEAR_REDO,
  DELETE_OBJECT,
  DISABLE_UNDO_REDO,
  HIDE_OBJECT_SETTINGS,
  LOAD_REDO_CANVAS_STATE,
  LOAD_UNDO_CANVAS_STATE,
  REDO_CANVAS_STATE,
  REORDER_FABRIC_OBJECTS,
  SET_ACTIVE_OBJECT,
  SET_ACTIVE_SETTINGS,
  SET_DEFAULT_IMAGE_OBJECT,
  SET_DEFAULT_TEXT_OBJECT,
  SET_FABRIC_OBJECTS,
  SET_IS_LOADED_FROM_HISTORY,
  SET_IS_OBJECTS_RESTORED,
  SET_IS_REDO_DISABLED,
  SET_IS_UNDO_DISABLED,
  SET_LAYER_NUMBER,
  SET_UNDO,
  TRIGGER_ADD_IMAGE,
  TRIGGER_ADD_TEXT,
  TRIGGER_DELETE_OBJECT,
  TRIGGER_SET_ACTIVE_OBJECT,
  TRIGGER_UPDATE_OBJECT,
  UNDO_CANVAS_STATE,
  UPDATE_ACTIVE_OBJECT,
  UPDATE_CANVAS_HISTORY,
  UPDATE_FABRIC_OBJECTS,
} from '@/store/Editor/constants';
import { IsMoreObjectsInNextState } from '@/store/Editor/helpers';
import {
  EditorState,
  HistoryActions,
  TriggerSetActiveObjectParams,
} from '@/store/Editor/types';
import { UPDATE_CANVAS_DATA } from '@/store/Templates/constants';

export const canvasHistoryActions = {
  [LOAD_REDO_CANVAS_STATE]: (
    { commit, state }: ActionContext<EditorState, any>,
  ): void => {
    if (IsMoreObjectsInNextState(state, HistoryActions.redo)) {
      commit(SET_IS_OBJECTS_RESTORED, false);
    }
    commit(REDO_CANVAS_STATE);
    commit(SET_ACTIVE_SETTINGS);
    commit(DISABLE_UNDO_REDO);
    commit(SET_IS_UNDO_DISABLED, false);
    if (!state.editorConfigs.isLoadedFromHistory) {
      commit(SET_IS_LOADED_FROM_HISTORY, true);
    }
    if (state.canvasHistory.redo.length) {
      commit(SET_IS_REDO_DISABLED, false);
    }
    commit(UPDATE_CANVAS_DATA);
  },
  [LOAD_UNDO_CANVAS_STATE]: (
    { commit, state }: ActionContext<EditorState, any>,
  ): void => {
    if (IsMoreObjectsInNextState(state, HistoryActions.undo)) {
      commit(SET_IS_OBJECTS_RESTORED, false);
    }
    commit(UNDO_CANVAS_STATE);
    commit(SET_ACTIVE_SETTINGS);
    commit(DISABLE_UNDO_REDO);
    commit(SET_IS_REDO_DISABLED, false);
    if (!state.editorConfigs.isLoadedFromHistory) {
      commit(SET_IS_LOADED_FROM_HISTORY, true);
    }
    if (state.canvasHistory.undo.length) {
      commit(SET_IS_UNDO_DISABLED, false);
    }
    commit(UPDATE_CANVAS_DATA);
  },
  [UPDATE_CANVAS_HISTORY]: (
    { commit, state }: ActionContext<EditorState, any>,
    isSaveLastState: boolean,
  ): void => {
    const isLoadedFromHistory = state.editorConfigs.isLoadedFromHistory;
    if (!isSaveLastState && isLoadedFromHistory) {
      commit(SET_IS_LOADED_FROM_HISTORY, false);
      commit(CLEAR_REDO);
      commit(SET_IS_REDO_DISABLED, true);
    }
    commit(SET_UNDO, state.canvasState);
    if (state.canvasHistory.undo.length) {
      commit(SET_IS_UNDO_DISABLED, false);
    }
  },
  [TRIGGER_ADD_IMAGE]: (
    { commit, dispatch }: ActionContext<EditorState, any>,
    file: File,
  ): void => {
    dispatch(UPDATE_CANVAS_HISTORY);
    commit(SET_DEFAULT_IMAGE_OBJECT, file);
  },
  [TRIGGER_ADD_TEXT]: (
    { commit, dispatch }: ActionContext<EditorState, any>,
  ): void => {
    dispatch(UPDATE_CANVAS_HISTORY);
    commit(SET_DEFAULT_TEXT_OBJECT);
  },
  [TRIGGER_DELETE_OBJECT]: (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    id?: number,
  ): void => {
    let objectToDelete: FabricObject = {};

    if (id) {
      objectToDelete = state.canvasState.fabricObjects
        .find(object => object.id === id);
    } else {
      objectToDelete = state.canvasState.activeObject;
      commit(SET_ACTIVE_OBJECT, null);
      commit(HIDE_OBJECT_SETTINGS);
    }

    dispatch(UPDATE_CANVAS_HISTORY);
    commit(DELETE_OBJECT, objectToDelete);
    commit(UPDATE_CANVAS_DATA);
  },
  [TRIGGER_UPDATE_OBJECT]: (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    {
      changes, id, isUpdateHistory,
    }: {
      changes: object, id: number; isUpdateHistory: boolean
    },
  ): void => {
    const activeObject = state.canvasState.activeObject;

    const objectToUpdate = id
      ? state.canvasState.fabricObjects
        .find(object => object.id === id)
      : activeObject;
  
    isUpdateHistory && dispatch(UPDATE_CANVAS_HISTORY);
    const overwrittenChanges = getValidatedObjectChanges(changes);
    if (!id || id === activeObject?.id) {
      commit(UPDATE_ACTIVE_OBJECT, overwrittenChanges);
    }
    commit(UPDATE_FABRIC_OBJECTS, { ...objectToUpdate, ...overwrittenChanges });
    commit(UPDATE_CANVAS_DATA);
  },
  [REORDER_FABRIC_OBJECTS]: (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    { from, to },
  ) => {
    dispatch(UPDATE_CANVAS_HISTORY);
    const [ background, ...fabricObjects ] = state.canvasState.fabricObjects;
    const [object] = fabricObjects.splice(from, 1);
    fabricObjects.splice(to, 0, object);
    const updatedFabricObjects = fabricObjects.map(
      (object, index) => ({ ...object, layerNumber: index }
      ));
    commit(SET_ACTIVE_OBJECT, null);
    commit(SET_LAYER_NUMBER, updatedFabricObjects.length);
    commit(SET_FABRIC_OBJECTS, [ background, ...updatedFabricObjects ]);
    commit(UPDATE_CANVAS_DATA);
  },
  [TRIGGER_SET_ACTIVE_OBJECT]: (
    { commit, dispatch }: ActionContext<EditorState, any>,
    { isUpdateHistory, object }: TriggerSetActiveObjectParams,
  ) => {
    isUpdateHistory && dispatch(UPDATE_CANVAS_HISTORY);
    const validatedObject = object ? getValidatedObjectChanges(object) : object;
    commit(SET_ACTIVE_OBJECT, validatedObject);
    commit(SET_ACTIVE_SETTINGS);
    object 
      ? commit(UPDATE_FABRIC_OBJECTS)
      : commit(HIDE_OBJECT_SETTINGS);
    commit(UPDATE_CANVAS_DATA);
  },
};
