
import BaseTable from '@/components/Common/BaseTable/BaseTable.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import CopyToClipboard from '@/components/CopyToClipboard/CopyToClipboard.vue';
import OrderStatusChip from '../components/OrderStatusChip/OrderStatusChip.vue';
import { useUserOrders } from './UserOrders';
export default {
  components: {
    OrderStatusChip,
    BaseTooltip,
    BaseTable,
    CopyToClipboard,
  },
  setup() {
    return useUserOrders();
  },
};
