
import BaseTable from '@/components/Common/BaseTable/BaseTable.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import ShopifyIcon from '@/components/icons/ShopifyIcon.vue';
import OrderStatusChip from '../components/OrderStatusChip/OrderStatusChip.vue';
import { useAdminOrders } from './AdminOrders';

export default {
  components: {
    ShopifyIcon,
    OrderStatusChip,
    BaseTooltip,
    BaseTable,
  },
  setup() {
    return useAdminOrders();
  },
};
