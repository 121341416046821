
import { black87 } from '@/constants/colors';
import { useBaseIcon } from './BaseIcon';
export default {
  props: {
    color: {
      default: black87,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 24,
      type: Number,
    },
  },
  setup(props) {
    return useBaseIcon(props);
  },
};
