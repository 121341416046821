import {
  FabricEvent,
  FabricObjectType,
} from '@/components/Editor/types/fabric';

export const getFontSizeOnResize = ({ target }: FabricEvent): void => {
  if (target.type === FabricObjectType.text) {
    if (target.fontSize * target.scaleX < 1) {
      target.scaleX = 1 / target.fontSize;
    }
    const cachedScaleX = target.scaleX;
    target.fontSize *= target.scaleX;
    const newFontSizeRounded = target.fontSize.toFixed(0);
    target.fontSize = newFontSizeRounded;
    target.scaleX = 1;
    target.scaleY = 1;
    target.width *= cachedScaleX;
    target._clearCache();
  }
};
