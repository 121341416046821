import { fabric } from 'fabric';
import { Mesh } from 'three';
import { Ref } from 'vue';
import {
  DEFAULT_FONT_SIZES,
  DEFAULT_FONTS,
} from '@/components/Editor/constants/defaultConfigs';
import { HEXColor } from './editor';

export enum ControlActionNames {
  scale = 'scale',
  width = 'width',
}

export interface DefaultImageObject {
  file: File,
  id: FabricObjectId,
  isDefault: boolean,
  layerName: string,
  layerNumber: number,
  type: FabricObjectType,
}

export interface DefaultTextObject {
  id: FabricObjectId,
  isDefault: boolean,
  layerName: string,
  layerNumber: number,
  type: FabricObjectType,
}

export type MeshRef = Ref<Mesh>;

export type FabricActiveSelection = fabric.ActiveSelection;

export type FabricCanvas = fabric.Canvas;

export type FabricControl = fabric.Control;

export type FabricEvent = fabric.Event;

export type FabricImage = fabric.Image;

export type FabricObject = FabricImage | FabricText;

export type FabricObjectRef = Ref;

export type FabricObjectId = string;

export type LargeCanvasStorage = Map<string, fabric.canvas>;
export type LargeCanvasStorageRef = Map<string, fabric.canvas>;

export type FabricCanvasRef = Ref<fabric.canvas>;

export type FabricTextObjects = FabricObject[];
export type FabricTextObjectsRef = Ref<FabricObject[]>;

export enum FabricObjectPosition {
  left='left',
  top='top'
}

export enum FabricObjectType {
  activeSelection = 'activeSelection',
  image = 'image',
  text = 'textbox',
  rect = 'rect',
}
export enum FabricObjectAttributes {
  angle = 'angle',
  id = 'id',
  fill = 'fill',
  fontFamily = 'fontFamily',
  fontSize = 'fontSize',
  left = 'left',
  textAlign = 'textAlign',
  top = 'top',
  scaleX = 'scaleX',
  scaleY = 'scaleY',
  width = 'width',
}

export interface FabricObjectProps {
  [FabricObjectAttributes.angle]?: number,
  [FabricObjectAttributes.id]?: FabricObjectId,
  [FabricObjectAttributes.fill]?: HEXColor,
  [FabricObjectAttributes.fontFamily]?: typeof DEFAULT_FONTS,
  [FabricObjectAttributes.fontSize]?: typeof DEFAULT_FONT_SIZES,
  [FabricObjectAttributes.left]?: number,
  [FabricObjectAttributes.textAlign]?: TextAlignments,
  [FabricObjectAttributes.top]?: number,
  [FabricObjectAttributes.scaleX]?: number,
  [FabricObjectAttributes.scaleY]?: number,
  [FabricObjectAttributes.width]?: number,
}

export type FabricPoint = fabric.Point;

export type FabricText = fabric.Text;

export type FabricTransform = fabric.transform;

export interface FabricObjectScale {
  scaleX: number,
  scaleY: number,
}

export enum TextAlignments {
  center = 'center',
  justify = 'justify',
  left = 'left',
  right = 'right',
}
export interface GetTextObject {
  fabricTextObjects: FabricTextObjectsRef,
  id: string,
  name: string,
}
