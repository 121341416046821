
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useCollectionsNotExists from './CollectionsNotExists';

export default {
  components: {
    BaseButton,
  },
  props: { collectionType: String },
  setup(props) {
    return useCollectionsNotExists(props);
  },
};
