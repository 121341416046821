import { HEXColor } from '@/components/Editor/types/editor';
import { FabricObject } from '@/components/Editor/types/fabric';
import {
  GET_ACTIVE_OBJECT,
  GET_BACKGROUND_COLOR,
  GET_FABRIC_OBJECTS,
  GET_IS_REDO_DISABLED,
  GET_IS_UNDO_DISABLED,
  GET_REDO,
  GET_UNDO,
} from '../constants';
import {
  CanvasState,
  EditorState,
} from '../types';

export const canvasHistoryGetters = {
  [GET_IS_REDO_DISABLED]: (state: EditorState): boolean => {
    return state.canvasHistory.isRedoDisabled;
  },
  [GET_IS_UNDO_DISABLED]: (state: EditorState): boolean => {
    return state.canvasHistory.isUndoDisabled;
  },
  [GET_REDO]: (state: EditorState): CanvasState[] => {
    return state.canvasHistory.redo;
  },
  [GET_UNDO]: (state: EditorState): CanvasState[] => {
    return state.canvasHistory.undo;
  },
  [GET_ACTIVE_OBJECT]: (state: EditorState): FabricObject => {
    return state.canvasState.activeObject;
  },
  [GET_BACKGROUND_COLOR]: (state: EditorState): HEXColor => {
    return state.canvasState.backgroundColor;
  },
  [GET_FABRIC_OBJECTS]: (state: EditorState): FabricObject[] => {
    return state.canvasState.fabricObjects;
  },
};
