import * as _ from 'lodash';
import { computed, onMounted, onUnmounted, reactive, ref, toRef, watch } from 'vue';
import { DEFAULT_TEXT_COLOR } from '@/components/Editor/constants/defaultConfigs';
import { ColorPickerType, HEXColor } from '@/components/Editor/types/editor';
import {
  FabricObject,
  FabricObjectAttributes,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  CHANGE_BACKGROUND_COLOR,
  GET_ACTIVE_OBJECT,
  GET_BACKGROUND_COLOR,
  GET_IS_MOBILE,
  GET_IS_SETTINS_DISABLED,
  SET_IS_SETTINS_DISABLED,
  TRIGGER_UPDATE_OBJECT,
} from '@/store/Editor/constants';

const useColorPicker = props => {
  const isPickerActive = ref(false);
  const isOverlayActive = ref(false);

  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );
  const isSettingsDisabled = computed(
    (): boolean => store.getters[GET_IS_SETTINS_DISABLED],
  );

  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);
  const color = computed((): HEXColor | void => {
    switch (props.colorPickerType) {
    case ColorPickerType.background:
      return store.getters[GET_BACKGROUND_COLOR];
    case ColorPickerType.text:
      return activeObject.value?.fill || DEFAULT_TEXT_COLOR;
    default: DEFAULT_TEXT_COLOR;
    }
  });

  const onPipetteActivate = (): void => {
    store.commit(SET_IS_SETTINS_DISABLED, true);
    isOverlayActive.value = true;
  };

  const onPipetteDeactivate = (): void => {
    store.commit(SET_IS_SETTINS_DISABLED, false);
    isOverlayActive.value = false;
  };

  const togglePicker = () => {
    if (!isSettingsDisabled.value) {
      isPickerActive.value = !isPickerActive.value;
    }
  };

  const closePicker = () => {
    isPickerActive.value = false;
  };

  const changeColorInStore = (color: string): void => {
    switch (props.colorPickerType) {
    case ColorPickerType.background:
      store.dispatch(CHANGE_BACKGROUND_COLOR, color);
      return;
    case ColorPickerType.text:
      store.dispatch(TRIGGER_UPDATE_OBJECT, {
        changes: { [FabricObjectAttributes.fill]: color },
        isUpdateHistory: true,
      });
      return;
    default: return;
    }
  };

  const applyColor = _.throttle(color => changeColorInStore(color), 500);

  const closePickerByEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') closePicker();
  };

  onMounted(() => document.addEventListener('keyup', closePickerByEscape));

  onUnmounted(() => document.removeEventListener('keyup', closePickerByEscape));

  const colorPickerProps = reactive({
    color,
    isLabelVisible: toRef(props, 'isLabelVisible'),
    isMobile,
    isPickerActive,
    isOverlayActive,
  });

  const closeColorPickerByClickingOnCanvas = (flag: boolean): void => {
    const canvasContainer = document.querySelector('.canvas-container')!;
    flag
      ? canvasContainer.addEventListener('touchend', closePicker)
      : canvasContainer.removeEventListener('touchend', closePicker);
  };

  watch(isPickerActive, (flag: boolean): void => {
    closeColorPickerByClickingOnCanvas(flag);
  });

  const colorPickerEmits = {
    applyColor,
    closePicker,
    togglePicker,
    onPipetteActivate,
    onPipetteDeactivate,
  };

  return {
    colorPickerEmits,
    colorPickerProps,
    isMobile,
  };
};

export default useColorPicker;
