
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Resolution from '@/components/Editor/Resolution/Resolution.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import { secondaryViolet } from '@/constants/colors';

export default {
  components: {
    Alignments,
    Resolution,
    Rotation,
  },
  props: {
    activeImage: {
      required: true,
      type: Object,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
    },
  },
  setup() {
    return { secondaryViolet };
  },
};
