
import useChangeModeNote from '@/components/Editor/ChangeModeNote/ChangeModeNote';
import ChangeModeNoteDesktop from './ChangeModeNoteDesktop/ChangeModeNoteDesktop.vue';
import ChangeModeNoteMobile from './ChangeModeNoteMobile/ChangeModeNoteMobile.vue';

export default {
  components: {
    ChangeModeNoteDesktop,
    ChangeModeNoteMobile,
  },
  props: {
    isShopifyPage: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useChangeModeNote();
  },
};
