
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import Alignments from '@/components/Editor/Alignments/Alignments.vue';
import Rotation from '@/components/Editor/Rotation/Rotation.vue';
import TextAlignments from '@/components/Editor/TextAlignments/TextAlignments.vue';
import TextFontSize from '@/components/Editor/TextFrontSize/TextFontSize.vue';
import useTextSettingsMobile from '@/components/Editor/TextSettings/TextSettingsMobile/TextSettingsMobile';
import AlignHorizontalCenterIcon from '@/components/icons/AlignHorizontalCenterIcon.vue';
import ChevronIcon from '@/components/icons/ChevronIcon.vue';
import FontSizeIcon from '@/components/icons/FontSizeIcon.vue';
import TextAlignLeftIcon from '@/components/icons/TextAlignLeftIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';

export default {
  components: {
    AlignHorizontalCenterIcon,
    Alignments,
    BaseButton,
    BaseIcon,
    ChevronIcon,
    ColorPicker,
    FontSizeIcon,
    Rotation,
    TextAlignLeftIcon,
    TextAlignments,
    TextFontSize,
    TrashcanIcon,
  },
  props: {
    font: {
      required: true,
      type: String,
    },
    isIframe: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    lockInfo: {
      type: Object,
      required: false,
      default: () => defaultLockObject,
    },
  },
  setup(props) {
    return useTextSettingsMobile(props);
  },
};
