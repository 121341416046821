import { fabric } from 'fabric';
import { OBJECT_ATTRIBUTES_TO_EXCLUDE } from '@/components/Editor/constants/customConfigs';
import {
  isObjectLocked,
  setObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import {
  FabricCanvas,
  FabricObject,
} from '@/components/Editor/types/fabric';
import {
  activeObjectCenterScaling,
} from '@/components/Editor/utils/activeObjectCenterScaling';
import { modifyDefaultFabricControls } from '../controls/modifyDefaultFabricControls';

const isObjectsOnCanvas = (
  canvas: FabricCanvas,
  activeSelectionFromStore: FabricObject,
): boolean => {
  const objectsOnCanvasIds = canvas
    .getObjects()
    .map((object: FabricObject): string => object.id);
  return activeSelectionFromStore._objects.every(
    (object: FabricObject): boolean => objectsOnCanvasIds.includes(object.id),
  );
};

const updateSelectedObjects = (
  activeSelectionFromStore: FabricObject,
  canvas: FabricCanvas,
): FabricObject[] => {
  return canvas.getObjects().filter(
    (objectFromCanvas: FabricObject): FabricObject => {
      return activeSelectionFromStore._objects
        .find((selectedObject: FabricObject): FabricObject => {
          if (selectedObject.id === objectFromCanvas.id) {
            const { group, ...selectedObjectProps } = selectedObject;
            objectFromCanvas.set(selectedObjectProps);
            objectFromCanvas.setCoords();
            return objectFromCanvas;
          }
        },
        ); 
    },
  );
};

export const changeActiveSelection = (
  activeSelectionFromStore: FabricObject,
  previousActiveObject: FabricObject,
  canvas: FabricCanvas,
  isLoadedFromHistory: boolean,
): void => {
  let activeSelectionFromCanvas = canvas.getActiveObject();
  if (isLoadedFromHistory) {
    if (!isObjectsOnCanvas(canvas, activeSelectionFromStore)) return;

    const {
      _objects,
      canvas: oldCanvas,
      objects,
      ...activeSelectionProps
    } = activeSelectionFromStore;

    if (!activeSelectionFromCanvas) {
      const selectedObjects = updateSelectedObjects(
        activeSelectionFromStore,
        canvas,
      );
      activeSelectionFromCanvas = new fabric.ActiveSelection(selectedObjects, {
        canvas,
        ...activeSelectionProps,
      });
      canvas._setActiveObject(activeSelectionFromCanvas);
    } else {
      activeSelectionFromCanvas.set({
        lockedInfo: {},
        ...activeSelectionProps,
        ...OBJECT_ATTRIBUTES_TO_EXCLUDE,
      });
    }

    if (
      isObjectLocked(activeSelectionFromStore)
      !== isObjectLocked(previousActiveObject)
    ) {
      setObjectLocked({
        isLocked: isObjectLocked(activeSelectionFromStore),
        object: activeSelectionFromCanvas,
        shouldSaveInStore: false,
      });
    }

    modifyDefaultFabricControls(activeSelectionFromCanvas);
    activeObjectCenterScaling(activeSelectionFromCanvas);

    activeSelectionFromCanvas.setObjectsCoords();
    activeSelectionFromCanvas.setCoords();
    canvas.renderAll();
  }
};
