import { MeshData, ScanTypes } from '@/components/Editor/types/editor';
import { FabricCanvas } from '@/components/Editor/types/fabric';

export interface pdfSize { height: number, width: number }

export interface getCanvasPdfParams {
  canvasForPdf: FabricCanvas | FabricCanvas[],
  canvasSize: THREE.Vector3,
  customFontList: string[],
  names?: string[],
  pdfSizeInMm: pdfSize,
  printOrder?: string,
  typeScan: ScanTypes,
}

export interface createScanParams {
  editableMesh: MeshData | null,
  typeScan: ScanTypes,
}

export interface enlargeCanvasParams {
  canvasFromEditor: FabricCanvas,
  isForScan: boolean,
  pdfSizeInPx?: pdfSize,
  targetCanvas: FabricCanvas
}

export interface canvasAsSvgMultiside {
  name: string,
  svg: string,
}

export enum pdfSizeUnit {
  px = 'px',
  mm = 'mm'
}

export enum multisideScanOrderTypes {
  portrait = 'portrait',
  landscape = 'landscape',
  order = 'order',
}
