import { FabricObject, FabricObjectType } from '@/components/Editor/types/fabric';
import store from '@/store';
import { GET_FABRIC_OBJECTS } from '@/store/Editor/constants';

const layerSlugs = {
  [FabricObjectType.text]: 'Text',
  [FabricObjectType.image]: 'Image',
};
export const getLayerName = (
  type: FabricObjectType.image | FabricObjectType.text,
  objects = store.getters[GET_FABRIC_OBJECTS],
): string => {

  const objectsByType = objects
    .filter(object => {
      if (object.type !== type) return false;
      const [ slug, count ] = object.layerName.split(' ');

      return slug === layerSlugs[type] && Number.isInteger(+count);
    })
    .map(object => object.layerName.split(' ')[1]);

  return `${layerSlugs[type]} ${
    objectsByType.length ? Math.max(...objectsByType) + 1 : 1
  }`;
};

export const setObjectsLayerName = (objects: FabricObject[]): void => {
  const objectsByType = {};
  for (const type in layerSlugs) {
    objectsByType[type] = objects.filter(obj => obj.type === type);
  }
  Object.values(objectsByType).forEach((objects: FabricObject) => {
    objects.forEach((object, index) => {
      if (object.layerName) return;
      object.layerName = `${layerSlugs[object.type]} ${index + 1}`;
    });
  });
};
