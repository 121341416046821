
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useProfileCardModal from './ProfileCardModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
    TextField,
  },
  props: {
    isOpen: Boolean,
  },
  setup() {
    return useProfileCardModal();
  },
};
