import { fabric } from 'fabric';
import {
  OBJECT_CUSTOM_ATTRIBUTES,
} from '@/components/Editor/constants/customConfigs';
import {
  FabricControl,
  FabricTransform,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import { TRIGGER_UPDATE_OBJECT } from '@/store/Editor/constants';
import {
  actualCanvasIconSize,
  renderScaleFromCenterIcon,
  renderScaleFromEdgeIcon,
} from './renderIcons';
import { actualCanvasIconOffset } from './renderIcons';

export const getScaleControl = (): FabricControl => {
  const canvasIconSize = actualCanvasIconSize();
  const canvasIconOffset = actualCanvasIconOffset();

  return new fabric.Control({
    cursorStyle: 'pointer',
    mouseUpHandler: (_, { target }: FabricTransform): void => {
      target.centeredScaling = !target.centeredScaling;
      target.controls.scaleControl.render = target.centeredScaling
        ? renderScaleFromCenterIcon
        : renderScaleFromEdgeIcon;
      target.canvas.requestRenderAll();

      store.dispatch(
        TRIGGER_UPDATE_OBJECT, {
          changes: target.toObject(OBJECT_CUSTOM_ATTRIBUTES),
          isUpdateHistory: true,
        },
      );
    },
    offsetX: -canvasIconOffset - canvasIconSize,
    offsetY: -canvasIconSize,
    sizeX: canvasIconSize,
    sizeY: canvasIconSize,
    render: renderScaleFromCenterIcon,
    x: 0,
    y: -0.5,
  });
};
