
import { Chrome } from 'vue-color';
import { isiOS } from '@/utils/isiOS';

export default {
  components: {
    AndroidPicker: Chrome,
  },
  props: {
    color: {
      required: true,
      type: String,
    },
    isLabelVisible: {
      default: true,
      required: false,
      type: Boolean,
    },
    isPickerActive: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return { isiOS };
  },
};
