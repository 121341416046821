
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useAddFontModal from '@/components/Editor/AddFontModal/AddFontModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
    BaseCheckbox,
  },
  props: {
    googleFontsList: Array,
    isOpen: Boolean,
  },
  setup() {
    return useAddFontModal();
  },
};
