
import TextField from '@/components/Common/TextField/TextField.vue';

export default {
  components: {
    TextField,
  },
  props: {
    rotation: {
      required: true,
      type: Number,
    },
  },
};
