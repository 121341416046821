
import ShippingZoneModal from '@/components/ShippingZoneModal/ShippingZoneModal.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useDeliveryProfile from '@/views/DeliveryProfile/DeliveryProfile';
import DeliveryProfileHeading from './DeliveryProfileHeading/DeliveryProfileHeading.vue';
import DeliveryProfileZones from './DeliveryProfileZones/DeliveryProfileZones.vue';

export default {
  components: {
    DeliveryProfileHeading,
    DeliveryProfileZones,
    MainLayout,
    ShippingZoneModal,
  },
  setup() {
    return useDeliveryProfile();
  },
};
