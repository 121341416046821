import { fabric } from 'fabric';
import { OBJECT_ATTRIBUTES_TO_EXCLUDE } from '@/components/Editor/constants/customConfigs';
import { DEFAULT_TEXT_SETTINGS } from '@/components/Editor/constants/defaultConfigs';
import { Size } from '@/components/Editor/types/editor';
import {
  FabricObject,
  FabricText,
} from '@/components/Editor/types/fabric';
import { centerObjectPosition } from '../objectModifiers/centerObjectPosition';

export const createFabricText = (
  textSettings: typeof DEFAULT_TEXT_SETTINGS | FabricText,
): FabricText => {
  return new fabric.Textbox(textSettings.text, {
    ...textSettings,
    ...OBJECT_ATTRIBUTES_TO_EXCLUDE,
  });
};

export const prepareDataForFabricText = ({
  canvasSize,
  id,
  layerName,
  layerNumber,
  zoomToDefault,
}:{
  canvasSize: Size,
  id: number,
  layerName: string,
  layerNumber: number,
  zoomToDefault: number,
}): FabricObject => {
  return {
    id,
    layerNumber,
    layerName,
    ...DEFAULT_TEXT_SETTINGS,
    ...centerObjectPosition(canvasSize, zoomToDefault),
  };
};
