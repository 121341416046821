
import { getDirective } from 'vue-debounce';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useSearchField from '@/components/SearchField/SearchField';
import SearchListDesktop from './SearchListDesktop/SearchListDesktop.vue';
import SearchListMobile from './SearchListMobile/SearchListMobile.vue';
    
export default {
  components: {
    BaseButton,
    SearchListDesktop,
    SearchListMobile,
    TextField,
  },
  directives: {
    debounce: getDirective(),
  },
  props: {
    isShowSearch: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useSearchField(props, context);
  },
};
