
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useControls from '@/components/Editor/EditorControls/EditorControls';
import PhotoAlbum from '@/components/icons/PhotoAlbum.vue';
export default {
  components: { BaseButton, PhotoAlbum },
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useControls();
  },
};
