import { CanvasTexture } from 'three';
import { MeshData } from '@/components/Editor/types/editor';
import store from '@/store';
import {
  SET_TEXTURES_TO_UPDATE,
  UPDATE_TEXTURE_CANVASES,
} from '@/store/Editor/constants';
import { CanvasType, createCanvas } from '../fabric/canvasModifiers/createCanvas';
import { enlargeCanvas } from '../fabric/canvasModifiers/enlargeCanvas';

export const updateCanvasTexture = async (
  mesh: MeshData,
): Promise<CanvasTexture> => {
  const textureCanvas = await createCanvas(mesh, CanvasType.texture);
  enlargeCanvas({
    targetCanvas: textureCanvas,
    canvasFromEditor: textureCanvas,
    isForScan: false,
  });
  const texture = new CanvasTexture(textureCanvas.lowerCanvasEl);
  store.commit(UPDATE_TEXTURE_CANVASES, {
    meshName: mesh.name,
    canvas: textureCanvas,
  });
  store.commit(SET_TEXTURES_TO_UPDATE, { meshName: mesh.name, texture });
  return texture;
};
