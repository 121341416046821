
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useCollectionCard from './CollectionCard';

export default {
  components: {
    BaseButton,
    BaseMenu,
    TextField,
  },
  props: {
    collection: Object,
    collectionType: String,
    isEditingRights: Boolean,
  },
  setup(props, context) {
    return useCollectionCard(props, context);
  },
};
