
import ContactSupportModal from '@/components/ContactSupportModal/ContactSupportModal.vue';
import useHeaderDesktop from '@/components/Header/HeaderDesktop/HeaderDesktop';
import PricingModal from '@/components/PricingModal/PricingModal.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu.vue';
import ReturnsPolicyModal from '@/components/ReturnsPolicyModal/ReturnsPolicyModal.vue';
import SearchField from '@/components/SearchField/SearchField.vue';

export default {
  components: {
    ContactSupportModal,
    PricingModal,
    ProfileMenu,
    SearchField,
    PrivacyPolicyModal,
    ReturnsPolicyModal,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    isContactSupportModalOpen: {
      required: true,
      type: Boolean,
    },
    isPricingModalOpen: {
      required: true,
      type: Boolean,
    },
    isPrivacyPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    isReturnsPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    linkToMyCollections: {
      required: true,
      type: String,
    },
    logoDefault: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useHeaderDesktop();
  },
};
