import { FabricObjectProps } from '@/components/Editor/types/fabric';
import { MAX_ROTATION_ANGLE } from '@/constants/rotationAngle';
import { roundToFixed } from '@/utils/math/roundToFixed';

export const limitRotation = (rotation: number, limit: number) =>
  roundToFixed((rotation + limit) % limit, 2);

export const validateAngle = (
  changes: FabricObjectProps,
): FabricObjectProps => {
  if (changes.angle !== undefined) {
    const limitedRotation = limitRotation(+changes.angle, MAX_ROTATION_ANGLE);
    changes.angle = roundToFixed(limitedRotation, 2);
  }
  return changes;
};

export const getValidatedObjectChanges = (
  changes: FabricObjectProps,
): FabricObjectProps => {
  const validatedChanges = { ...changes };

  validateAngle(validatedChanges);

  return validatedChanges;
};
