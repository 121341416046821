
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import HandIcon from '@/components/icons/HandIcon.vue';
import PointerIcon from '@/components/icons/PointerIcon.vue';
import { useSceneControls } from './SceneControls';

export default {
  components: {
    HandIcon,
    BaseButton,
    PointerIcon,
    BaseIcon,
  },
  props: {
    isSideSelectionEnabled: {
      default: false,
      type: Boolean,
    },
    isMeshSelectionRemoved: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    return useSceneControls();
  },
};
