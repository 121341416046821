
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import useSideControlsMobile from '@/components/Editor/EditorSideControls/EditorSideControlsMobile/EditorSideControlsMobile';
import ImageSettings from '@/components/Editor/ImageSettings/ImageSettings.vue';
import TextSettings from '@/components/Editor/TextSettings/TextSettings.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import {
  ImageToolIconData1,
  ImageToolIconData2,
  ImageToolIconData3,
  ImageToolIconData4,
  ImageToolIconData5,
  TextToolIconData1,
  TextToolIconData2,
  TextToolIconData3,
  TextToolIconData4,
  TextToolIconData5,
} from '@/constants/editor';

export default {
  components: {
    ColorPicker,
    ImageSettings,
    TextSettings,
    BaseIcon,
    TrashcanIcon,
  },
  props: {
    isSideControlsDisabled: {
      required: false,
      type: Boolean,
    },
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isImageActive: {
      required: true,
      type: Boolean,
    },
    isImageLoading: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    isTextActive: {
      required: true,
      type: Boolean,
    },
    secondaryViolet: {
      required: true,
      type: String,
    },
    activeObject: {
      required: false,
      type: Object,
    },
    lockInfo: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return {
      ImageToolIconData1,
      ImageToolIconData2,
      ImageToolIconData3,
      ImageToolIconData4,
      ImageToolIconData5,
      TextToolIconData1,
      TextToolIconData2,
      TextToolIconData3,
      TextToolIconData4,
      TextToolIconData5,
      ...useSideControlsMobile(props, context),
    };
  },
};
