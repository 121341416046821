
import useSideControls from './EditorSideControls';
import EditorSideControlsDesktop from './EditorSideControlsDesktop/EditorSideControlsDesktop.vue';
import EditorSideControlsMobile from './EditorSideControlsMobile/EditorSideControlsMobile.vue';

export default {
  components: {
    EditorSideControlsDesktop,
    EditorSideControlsMobile,
  },
  props:{
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useSideControls(props, context);
  },
};
