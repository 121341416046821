
import BurgerMenu from '@/components/BurgerMenu/BurgerMenu.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ContactSupportModal from '@/components/ContactSupportModal/ContactSupportModal.vue';
import useHeaderMobile from '@/components/Header/HeaderMobile/HeaderMobile';
import PricingModal from '@/components/PricingModal/PricingModal.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import ReturnsPolicyModal from '@/components/ReturnsPolicyModal/ReturnsPolicyModal.vue';
import SearchField from '@/components/SearchField/SearchField.vue';

export default {
  components: {
    BaseButton,
    BurgerMenu,
    ContactSupportModal,
    PricingModal,
    SearchField,
    PrivacyPolicyModal,
    ReturnsPolicyModal,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    isContactSupportModalOpen: {
      required: true,
      type: Boolean,
    },
    isPricingModalOpen: {
      required: true,
      type: Boolean,
    },
    isPrivacyPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    isReturnsPolicyModalOpen: {
      required: true,
      type: Boolean,
    },
    linkToMyCollections: {
      required: true,
      type: String,
    },
    logoDefault: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useHeaderMobile();
  },
};
