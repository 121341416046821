
import useShippingTable from './ShippingTable';

export default {
  props: {
    amountOfPages: {
      required: true,
      type: Number,
    },
    currentPage: {
      required: true,
      type: Number,
    },
    deliveryProfiles: {
      required: true,
      type: Array,
    },
    isShowPagination: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useShippingTable();
  },
};
