
import HighlightedName from '../HighlightedName/HighlightedName.vue';
export default {
  components: {
    HighlightedName,
  },
  props: {
    foundProducts: Object,
    search: {
      required: true,
      type: String,
    },
  },
};
