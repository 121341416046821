
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import ImageToolIcon from '@/components/icons/ImageToolIcon.vue';
import TextToolIcon from '@/components/icons/TextToolIcon.vue';
import { useEditorSideSummary } from './EditorSideSummary';

export default {
  components: {
    ImageToolIcon,
    TextToolIcon,
    TextField,
    BaseButton,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'saveSummary', 'openEditSummary', 'remove' ],
  setup(props) {
    return useEditorSideSummary(props);
  },
};
