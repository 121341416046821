
import { primaryViolet } from '@/constants/colors';
import { checkboxRequired } from '@/utils/FieldValidationRules/index';

export default {
  props: {
    noMarginTop: {
      required: false,
      type: Boolean,
    },
    required: {
      required: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      checkboxRules: [checkboxRequired],
      primaryViolet,
    };
  },
};
