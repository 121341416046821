
import { black06, black87 } from '@/constants/colors';
import { isTabletAndLower } from '@/utils/Mobile';

export default {
  props: {
    system: {
      required: true,
      type: Object,
    },
  },
  setup() {
    return {
      black06,
      black87,
      isTabletAndLower,
    };
  },
};
