import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';

export const isObjectOnCanvas = (
  canvas: FabricCanvas,
  object: FabricObject,
): boolean => {
  return canvas
    .getObjects()
    .find(({ id }: FabricObject): boolean => {
      return id === object.id;
    });
};
