
import { Drag, DropList } from 'vue-easy-dnd';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ColorPicker from '@/components/Common/ColorPicker/ColorPicker.vue';
import { defaultLockObject } from '@/components/Editor//helpers/fabric/lockObjects/types';
import useSideControlsDesktop from '@/components/Editor/EditorSideControls/EditorSideControlsDesktop/EditorSideControlsDesktop';
import FontSelect from '@/components/Editor/FontSelect/FontSelect.vue';
import ImageSettings from '@/components/Editor/ImageSettings/ImageSettings.vue';
import TextSettings from '@/components/Editor/TextSettings/TextSettings.vue';
import EditorSideSummary from './EditoSideSummary/EditorSideSummary.vue';
export default {
  components: {
    ColorPicker,
    FontSelect,
    ImageSettings,
    TextSettings,
    BaseButton,
    EditorSideSummary,
    Drag,
    DropList,
  },
  props:{
    isCustomizationFrame: {
      required: true,
      type: Boolean,
    },
    isFontSelectOpen: {
      required: true,
      type: Boolean,
    },
    isImageActive: {
      required: true,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    isTextActive: {
      required: true,
      type: Boolean,
    },
    secondaryViolet: {
      required: true,
      type: String,
    },
    activeMesh: {
      required: false,
      type: Object,
    },
    isImageLoading: {
      required: true,
      type: Boolean,
    },
    isSideControlsDisabled: {
      required: false,
      type: Boolean,
    },
    activeObject: {
      required: false,
      type: Object,
    },
    lockInfo: {
      required: false,
      type: Object,
      default: () => defaultLockObject,
    },
  },
  setup(props, context) {
    return useSideControlsDesktop(props, context);
  },
};
