
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';

export default {
  components: {
    BaseButton,
    TextField,
  },
  props: {
    isNameEditing: {
      required: true,
      type: Boolean,
    },
    isSaving: {
      required: true,
      type: Boolean,
    },
    isPublic: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    isValidName: {
      required: true,
      type: Boolean,
    },
    templateName: {
      type: String,
    },
    templateNameRules: {
      required: true,
      type: Array,
    },
    white50: {
      required: true,
      type: String,
    },
  },
  setup() {
    const ignorableClickOutsideElements = [document.querySelector('.global-alert')];
    return {
      ignorableClickOutsideElements,
    };
  },
};
