
import { isNil } from 'lodash';
import { PropType } from 'vue';
import CopyToClipboard from '@/components/CopyToClipboard/CopyToClipboard.vue';
import ShopifyIcon from '@/components/icons/ShopifyIcon.vue';
import { black54, black60 } from '@/constants/colors';
import { OrderStatus } from '@/store/Orders/types';
import { parseDate } from '@/utils/date';
import OrderStatusChip from '../OrderStatusChip/OrderStatusChip.vue';
export default {
  components: { OrderStatusChip, ShopifyIcon, CopyToClipboard },
  props: {
    id: {
      type: Number,
      required: true,
    },
    status: {
      type: String as PropType<OrderStatus>,
      required: true,
    },
    date: {
      type: String,
      require: true,
    },
    time: {
      type: String,
      required: true,
    },
    store: {
      type: String,
    },
    price: {
      type: Number,
    },
    shopifyOrderId: {
      type: Number,
    },
    country: {
      type: String,
    },
    customerName: {
      type: String,
    },
  },
  setup() {
    return {
      parseDate,
      black54,
      black60,
      isNil,
    };
  },
};
