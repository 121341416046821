import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import { GUIDELINE_OFFSET } from './constants';
import {
  EditorGuidelinesVisibility,
  EditorHorizontalGuidelinesDirection,
  EditorHorizontalLinesNames,
  EditorVerticalGuidelinesDirection,
  EditorVerticalLinesNames,
  Guideline,
} from './types';

const isRotated = (angle: number): boolean => angle % 360 !== 0;

const inOffsetRange = (guidelinePos: number, objectPos: number): boolean => {
  return Math.abs(guidelinePos - objectPos) < GUIDELINE_OFFSET;
};

const snapObject = (
  activeObject: FabricObject,
  position: { x: number; y: number },
): void => {
  activeObject.setPositionByOrigin(position, 'center', 'center');
};

export const snapVerticalObject = (
  activeObject: FabricObject,
  newPos: number,
): void => {
  const { width } = activeObject.getBoundingRect(true);

  const { y } = activeObject.getCenterPoint();
  const position = { x: newPos + width / 2, y };
  snapObject(activeObject, position);
};

export const snapHorizontalObject = (
  activeObject: FabricObject,
  newPos: number,
): void => {
  const { height } = activeObject.getBoundingRect(true);

  const { x } = activeObject.getCenterPoint();
  const position = { x, y: newPos - height / 2 };

  snapObject(activeObject, position);
};

export const getVisibleGuidelinesByRotation = (
  obj: FabricObject | FabricCanvas,
  activeObject: FabricObject,
): EditorGuidelinesVisibility => {
  const isObjectRotated = isRotated(obj.angle);
  const isActiveObjectRotated = isRotated(activeObject.angle);
  const isGuidelineVisible = !isObjectRotated && !isActiveObjectRotated;
  return {
    horizontalBottom: isGuidelineVisible,
    horizontalCenter: true,
    horizontalTop: isGuidelineVisible,
    verticalCenter: true,
    verticalLeft: isGuidelineVisible,
    verticalRight: isGuidelineVisible,
  };
};

export const isVerticalIntersects = (
  verticalGuideline: Guideline,
  objectPos: number,
): boolean => inOffsetRange(verticalGuideline.left, objectPos);

export const isHorizontalIntersects = (
  horizontalGuideline: Guideline,
  objectPos: number,
): boolean => inOffsetRange(horizontalGuideline.top, objectPos);

export const getVisibleGuidelines = (
  guidelinesVisibility: EditorGuidelinesVisibility,
): {
  horizontalLines: EditorHorizontalLinesNames[];
  verticalLines: EditorVerticalLinesNames[];
} => {
  const verticalLines = Object.keys(EditorVerticalGuidelinesDirection).filter(
    line => guidelinesVisibility[line],
  ) as EditorVerticalLinesNames[];

  const horizontalLines = Object.keys(EditorHorizontalGuidelinesDirection)
    .filter(
      line => guidelinesVisibility[line],
    ) as EditorHorizontalLinesNames[];

  return { horizontalLines, verticalLines };
};
