
import Header from '@/components/Header/Header.vue';
import { useMainLayout } from '@/layouts/MainLayout/MainLayout';

export default {
  components: {
    Header,
  },
  setup() {
    return useMainLayout();
  },
};
