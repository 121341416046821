import Hammer from 'hammerjs';
import { ANGLE_TO_START_ROTATE } from '@/components/Editor/constants/customConfigs';
import { limitRotation } from '@/components/Editor/helpers/fabric/objectModifiers/validateObjectProps';
import { TouchData } from '@/components/Editor/types/editor';
import { FabricCanvas, FabricObject } from '@/components/Editor/types/fabric';
import { MAX_ROTATION_ANGLE } from '@/constants/rotationAngle';
import { ToggleTypes } from '@/types';

let hammer;
let pinch;
let rotate;

const touchData: TouchData = {
  canvas: null,
  initialPinchScaleX: 0,
  initialPinchScaleY: 0,
  initialRotateTheta: 0,
  initialRotateAngle: 0,
  previousObjectScale: 0,
};

const setInitialTouchData = (
  { rotation }: typeof Hammer.Input,
): void => {
  const object = touchData.canvas.getActiveObject();
  if (object) {
    const { angle, scaleX, scaleY } = object;
    touchData.initialRotateAngle = 0;
    touchData.initialRotateTheta = rotation - angle;
    touchData.initialPinchScaleX = scaleX;
    touchData.initialPinchScaleY = scaleY;
  }
};

const scaleObject = (
  object: FabricObject,
  scale: number,
): void => {
  if (object?.lockedInfo?.lockedScaling) return;
  if (touchData.previousObjectScale !== scale) {
    object.scaleX = scale * touchData.initialPinchScaleX;
    object.scaleY = scale * touchData.initialPinchScaleY;
    touchData.previousObjectScale = scale;
    touchData.canvas.fire('object:scaling', { target: object });
  }
};

const rotateObject = (
  object: FabricObject,
  rotation: number,
): void => {
  if (object?.lockedInfo?.lockedRotation) return;
  const { initialRotateAngle, initialRotateTheta } = touchData;
  const rotationTheta = rotation - initialRotateAngle - initialRotateTheta;
  if (Math.abs(rotationTheta) > ANGLE_TO_START_ROTATE) {
    const limitedRotation = limitRotation(
      rotation - initialRotateTheta,
      MAX_ROTATION_ANGLE,
    );
    object.rotate(limitedRotation);
  } else {
    object.rotate(initialRotateAngle);
  }
};

const modifyObject = (event: typeof Hammer.Input): void => {
  const object = touchData.canvas.getActiveObject();
  if (object) {
    const { rotation, scale } = event;
    scaleObject(object, scale);
    rotateObject(object, rotation);
    touchData.canvas.requestRenderAll();
  }
};

const fireFabricScale = (): void => {
  const object = touchData.canvas.getActiveObject();
  if (object) {
    touchData.canvas.fire('object:scaled', { target: object });
  }
};

export const touchEvents = (
  canvas: FabricCanvas,
  toggle: ToggleTypes,
): void => {
  touchData.canvas = canvas;
  switch (toggle) {
  case ToggleTypes.on:
    hammer = new Hammer.Manager(canvas.upperCanvasEl);
    pinch = new Hammer.Pinch();
    rotate = new Hammer.Rotate();
    hammer.add([ pinch, rotate ]);
    hammer.on('pinchstart rotatestart', setInitialTouchData);
    hammer.on('pinchmove rotatemove', modifyObject);
    hammer.on('pinchend', fireFabricScale);
    break;
  case ToggleTypes.off:
    if (hammer) {
      hammer.off('pinchstart rotatestart', setInitialTouchData);
      hammer.off('pinchmove rotatemove', modifyObject);
      hammer.off('pinchend', fireFabricScale);
      hammer.remove([ pinch, rotate ]);
      hammer.stop();
      hammer.destroy();
      rotate = null;
      pinch = null;
      hammer = null;
    }
    break;
  default: break;
  }
};
