
import useHeader from '@/components/Header/Header';
import HeaderDesktop from './HeaderDesktop/HeaderDesktop.vue';
import HeaderMobile from './HeaderMobile/HeaderMobile.vue';

export default {
  components: {
    HeaderDesktop,
    HeaderMobile,
  },
  setup() {
    return useHeader();
  },
};
