
import { OverflowValues } from '@/types';
import { setBodyOverflowY } from '@/utils';
import { isTabletAndLower } from '@/utils/Mobile';
import AuthBackground from './AuthBackground/AuthBackground.vue';

export default {
  components: {
    AuthBackground,
  },
  props: {
    isFullHeight: {
      required: false,
      type: Boolean,
    },
  },
  beforeDestroy() {
    !isTabletAndLower && setBodyOverflowY(OverflowValues.initial);
  },
  mounted() {
    !isTabletAndLower && setBodyOverflowY(OverflowValues.hidden);
  },
};
