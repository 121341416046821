
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { primaryViolet } from '@/constants/colors';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return {
      primaryViolet,
    };
  },
};
