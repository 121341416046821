
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import useDeliveryProfileZone from './DeliveryProfileZone';
import RateTable from './RateTable/RateTable.vue';

export default {
  components: {
    BaseButton,
    BaseMenu,
    RateTable,
  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    return useDeliveryProfileZone(props, context);
  },
};
