
import useAlignments from '@/components/Editor/TextAlignments/TextAlignments';
import TextAlignmentsDesktop from './TextAlignmentsDesktop/TextAlignmentsDesktop.vue';
import TextAlignmentsMobile from './TextAlignmentsMobile/TextAlignmentsMobile.vue';

export default {
  components: {
    TextAlignmentsDesktop,
    TextAlignmentsMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return useAlignments();
  },
};
