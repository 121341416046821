import { fabric } from 'fabric';
import {
  getValidatedObjectChanges,
} from '@/components/Editor/helpers/fabric/objectModifiers/validateObjectProps';
import { FabricObject } from '@/components/Editor/types/fabric';
import { FabricObjectType } from '@/components/Editor/types/fabric';
export const updateObjectDataFromSelection = (
  objectToUpdate: FabricObject,
  objectFromSelection: FabricObject,
): void => {
  const transformMatrix = objectFromSelection.calcTransformMatrix();
  const { scaleX: previousScaleX, scaleY: previousScaleY } = objectToUpdate; 
  const {
    angle,
    scaleX,
    scaleY,
    translateX,
    translateY,
  } = fabric.util.qrDecompose(transformMatrix);

  if (
    objectToUpdate === FabricObjectType.image
      && (previousScaleX !== scaleX || previousScaleY !== scaleY)
  ) {
    objectToUpdate.isScalingChanged = true;
  }
  
  objectToUpdate.angle = angle;
  objectToUpdate.left = translateX;
  objectToUpdate.scaleX = scaleX;
  objectToUpdate.scaleY = scaleY;
  objectToUpdate.top = translateY;
  objectToUpdate = getValidatedObjectChanges(objectToUpdate);
};

export const updateObjectsDataFromSelection = (
  dataToUpdate: FabricObject[],
  activeSelection: FabricObject,
): void => {
  activeSelection._objects.forEach((selectedObject: FabricObject): void => {
    const object = dataToUpdate.find((object: FabricObject): boolean => {
      return object.id === selectedObject.id;
    });
    updateObjectDataFromSelection(
      object,
      selectedObject,
    );
  });
};
