
import { useEditorCanvas } from './EditorCanvas';

export default {
  props: {
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
    isImageLoading: {
      default: false,
      type: Boolean,
    },
    isObjectSelected: {
      default: false,
      type: Boolean,
    },
    isMobile: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    return useEditorCanvas();
  },
};
