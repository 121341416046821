
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import BaseSelect from '@/components/Common/BaseSelect/BaseSelect.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import ShopifyDeactivatedStore from './ShopifyDeactivatedStore/ShopifyDeactivatedStore.vue';
import useShopifyThemeSettings from './ShopifyThemeSettings';
import ShopifyThemeSettingsModal from './ShopifyThemeSettingsModal/ShopifyThemeSettingsModal.vue';
import ThemeSelectorMobile from './ThemeSelectorMobile/ThemeSelectorMobile.vue';

export default {
  components: {
    BaseButton,
    BaseMenu,
    BaseSelect,
    BaseTooltip,
    ShopifyDeactivatedStore,
    ShopifyThemeSettingsModal,
    ThemeSelectorMobile,
  },
  props: {
    currentThemeId: [ String, Number ],
    currentThemeName: String,
    item: Object,
    themes: Array,
    userStore: Object,
  },
  emits: [ 'updateTheme', 'setCurrentThemeId', 'resetChanges' ],
  setup(props) {
    return useShopifyThemeSettings(props);
  },
};
