import { render, staticRenderFns } from "./ShopifyPreview.vue?vue&type=template&id=d37f1788&scoped=true&"
import script from "./ShopifyPreview.vue?vue&type=script&lang=ts&"
export * from "./ShopifyPreview.vue?vue&type=script&lang=ts&"
import style0 from "./ShopifyPreview.vue?vue&type=style&index=0&id=d37f1788&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d37f1788",
  null
  
)

export default component.exports