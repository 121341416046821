
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import usePricingModal from './PricingModal';

export default {
  components: {
    BackToTopButton,
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return usePricingModal();
  },
};
