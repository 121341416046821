
import AddToMyCollectionsModal from '@/components/AddToMyCollectionsModal/AddToMyCollectionsModal.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import AddFontModal from '@/components/Editor/AddFontModal/AddFontModal.vue';
import AddToOrderPanel from '@/components/Editor/AddToOrderPanel/AddToOrderPanel.vue';
import ChangeModeNote from '@/components/Editor/ChangeModeNote/ChangeModeNote.vue';
import EditorCanvas from '@/components/Editor/EditorCanvas/EditorCanvas.vue';
import EditorControls from '@/components/Editor/EditorControls/EditorControls.vue';
import EditorHeader from '@/components/Editor/EditorHeader/EditorHeader.vue';
import EditorScaleControls from '@/components/Editor/EditorScaleControls/EditorScaleControls.vue';
import EditorSideControls from '@/components/Editor/EditorSideControls/EditorSideControls.vue';
import RemoveFontModal from '@/components/Editor/RemoveFontModal/RemoveFontModal.vue';
import Scene from '@/components/Editor/Scene/Scene.vue';
import SceneControls from '@/components/Editor/SceneControls/SceneControls.vue';
import TemplateDesignSidebar from '@/components/Editor/TemplateDesignSidebar/TemplateDesignSidebar.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';
import ViewSwitcher from '@/components/Editor/ViewSwitcher/ViewSwitcher.vue';
import BookMarkIcon from '@/components/icons/BookMarkIcon.vue';
import useEditor from './Editor';

export default {
  components: {
    AddFontModal,
    AddToMyCollectionsModal,
    AddToOrderPanel,
    ChangeModeNote,
    EditorCanvas,
    Scene,
    EditorControls,
    EditorHeader,
    EditorScaleControls,
    EditorSideControls,
    RemoveFontModal,
    UndoRedoIcons,
    ViewSwitcher,
    TemplateDesignSidebar,
    BaseButton,
    BookMarkIcon,
    SceneControls,
  },
  props: {
    templateId: [ String, Number ],
  },
  setup() {
    return useEditor();
  },
};
