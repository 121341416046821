
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import Icon from '@/components/Editor/TextAlignIcons/TextAlignIcons.vue';

export default {
  components: {
    BaseButton,
    Icon,
  },
  props: {
    textAlignment: {
      required: true,
      type: String,
    },
    textAlignments: {
      required: true,
      type: Array,
    },
  },
  emits: ['triggerTextAlignment'],
};
