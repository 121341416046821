
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseMenu from '@/components/Common/BaseMenu/BaseMenu.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useTemplateCard from './TemplateCard';

export default {
  components: {
    BaseButton,
    BaseMenu,
    TextField,
  },
  props: {
    collection: Object,
    isEditingRights: Boolean,
    template: Object,
  },
  setup(props, context) {
    return useTemplateCard(props, context);
  },
};
