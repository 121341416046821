
export default {
  props: {
    bottom: {
      required: false,
      type: Boolean,
    },
    contentClass: {
      required: false,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    maxWidth: {
      default: 230,
      required: false,
      type: Number,
    },
    mdiIcon: {
      required: false,
      type: Boolean,
    },
    top: {
      required: false,
      type: Boolean,
    },
  },
};
