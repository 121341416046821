
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';  
import useConfirmRemoveCollectionModal from './ConfirmRemoveCollectionModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    collection: Object,
    isOpen: Boolean,
    templatesInCollection: Array,
  },
  setup(props, context) {
    return useConfirmRemoveCollectionModal(props, context);
  },
};
