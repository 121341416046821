
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { primaryViolet } from '@/constants/colors';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    isOpen: Boolean,
    isResetedThemes: Boolean,
    isResettingThemes: Boolean,
  },
  setup() {
    return {
      primaryViolet,
    };
  },
};
