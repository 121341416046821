

import { useResolution } from './Resolution';
import ResolutionDesktop from './ResolutionDesktop/ResolutionDesktop.vue';
import ResolutionMobile from './ResolutionMobile/ResolutionMobile.vue';
export default {
  components: {
    ResolutionDesktop,
    ResolutionMobile,
  },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    return useResolution(props);
  },
};
