
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';

export default {
  components: {
    BaseButton,
    UndoRedoIcons,
  },
  props: {
    from3D: {
      required: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    isMobile: {
      required: true,
      type: Boolean,
    },
    templateName: {
      required: true,
      type: String,
    },
    isObjectSelected: {
      default: false,
      type: Boolean,
    },
  },
};
