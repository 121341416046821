import { fabric } from 'fabric';
import { uuid } from 'vue-uuid';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { FabricCanvas, FabricObject, FabricObjectType } from '@/components/Editor/types/fabric';
import { getCanvasBlob } from '@/utils/canvas';

export const clearCanvas = (canvas: FabricCanvas): void => {
  const objects = canvas?.getObjects()
    .filter(({ id }) => id !== BACKGROUND_OBJECT_ID);
  
  objects.forEach(obj => {
    canvas.remove(obj);
  });
};

export const cloneObject = (obj: FabricObject): Promise<FabricObject> =>
  new Promise(resolve => {
    obj.clone(cloned => {
      resolve(cloned);
    });
  });

export const copyObjects = async (
  canvas: FabricCanvas,
): Promise<FabricObject[]> => {
  const cloneTypes = [ FabricObjectType.text, FabricObjectType.image ];
  const allObjects = canvas.getObjects();
  const objects = allObjects.filter(obj => cloneTypes.includes(obj.type));
  const clonedObjects = await Promise.all(
    objects.map(async obj => cloneObject(obj)),
  );
  return clonedObjects;
};

export const getCanvasScreenshotFile = async (
  canvas: FabricCanvas,
  filename: string,
  type = 'image/jpeg',
): Promise<File | null> => {
  const imgBlob = await getCanvasBlob(canvas.lowerCanvasEl, type);
  if (!imgBlob) return null;
  const img = new File([imgBlob], filename, { type });
  return img;
};

export const getEnlivenObjects = (
  objects: FabricObject[],
  { generateId }: { generateId?: boolean },
): Promise<FabricObject[]> =>
  new Promise(resolve =>
    fabric.util.enlivenObjects(objects, (enlivenObjects: FabricObject[]) => {
      if (generateId) {
        const uniqueEnlivenObjects = enlivenObjects.map(obj => ({
          ...obj,
          id: uuid.v4(),
        }));
        resolve(uniqueEnlivenObjects);
        return;
      }
      resolve(enlivenObjects);
    }),
  );
