
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import DontHaveAccount from '@/components/Common/DontHaveAccount/DontHaveAccount.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useForgotPasswordForm from './ForgotPasswordForm';

export default {
  components: {
    BaseButton,
    BaseTitle,
    DontHaveAccount,
    TextField,
  },
  setup() {
    return useForgotPasswordForm();
  },
};
