import { render, staticRenderFns } from "./EditorCanvas.vue?vue&type=template&id=622c7faa&scoped=true&"
import script from "./EditorCanvas.vue?vue&type=script&lang=ts&"
export * from "./EditorCanvas.vue?vue&type=script&lang=ts&"
import style0 from "./EditorCanvas.vue?vue&type=style&index=0&id=622c7faa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622c7faa",
  null
  
)

export default component.exports