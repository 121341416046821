
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useProfile from './Profile';
import ProfileCard from './ProfileCard/ProfileCard.vue';
import ProfileSystem from './ProfileSystem/ProfileSystem.vue';
import ShopifyThemeSettings from './ShopifyThemeSettings/ShopifyThemeSettings.vue';

export default {
  components: {
    MainLayout,
    ProfileCard,
    ProfileSystem,
    ShopifyThemeSettings,
  },
  setup() {
    return useProfile();
  },
};
