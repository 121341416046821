
import BaseIndicator from '@/components/Common/BaseIndicator/BaseIndicator.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
export default {
  components: { BaseIndicator, BaseTooltip },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    dpiIndicatorColor: {
      required: true,
      type: String,
    },
  },
};
