
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useRateTableItem from './RateTableItem';

export default {
  components: {
    BaseButton,
    TextField,
  },
  props: {
    isEditingItem: {
      default: false,
      type: Boolean,
    },
    item: {
      required: true,
      type: Object,
    },
    zone: {
      required: true,
      type: Object,
    },
    isValidForm: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    return useRateTableItem(props, context);
  },
};
