
import DeliveryProfileZone from './DeliveryProfileZone/DeliveryProfileZone.vue';

export default {
  components: {
    DeliveryProfileZone,
  },
  props: {
    zones: {
      required: true,
      type: Array,
    },
  },
};
