
import useEditorScaleControls from '@/components/Editor/EditorScaleControls/EditorScaleControls';

export default {
  props: {
    currentFabricZoom: {
      default: 1,
      type: Number,
    },
    isShopifyPage: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useEditorScaleControls(props, context);
  },
};
