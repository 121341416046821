
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { useShopifyDeactivatedStore } from './ShopifyDeactivatedStore';
export default {
  components: { BaseButton },
  props: {
    website: {
      required: true,
      type: String,
    },
  },
  setup() {
    return useShopifyDeactivatedStore();
  },
};
