
import useAlignments from '@/components/Editor/Alignments/Alignments';
import AlignmentsDesktop from './AlignmentsDesktop/AlignmentsDesktop.vue';
import AlignmentsMobile from './AlignmentsMobile/AlignmentsMobile.vue';

export default {
  components: {
    AlignmentsDesktop,
    AlignmentsMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return useAlignments();
  },
};
