
import ProductUnavailable from '@/components/ProductUnavailable/ProductUnavailable.vue';
import Editor from '@/views/Editor/Editor.vue';

import { useShopifyPreview } from './ShopifyPreview';
export default {
  components: {
    Editor,
    ProductUnavailable,
  },
  setup() {
    return useShopifyPreview();
  },
};
