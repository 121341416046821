
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useShipping from './Shipping';
import ShippingHeading from './ShippingHeading/ShippingHeading.vue';
import ShippingTable from './ShippingTable/ShippingTable.vue';

export default {
  components: {
    MainLayout,
    ShippingHeading,
    ShippingTable,
  },
  setup() {
    return useShipping();
  },
};
