/* eslint-disable max-lines */
import { fabric } from 'fabric';
import {
  CanvasTexture,
  Color,
  DirectionalLight,
  DoubleSide,
  FileLoader,
  GammaEncoding,
  Group,
  Mesh,
  MeshPhysicalMaterial,
  PerspectiveCamera,
  Raycaster,
  RepeatWrapping,
  Scene,
  sRGBEncoding,
  Vector2,
  WebGLRenderer,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { computed, onBeforeUnmount, onMounted, ref, Ref, toRef, watch } from 'vue';
import { fitCameraToObject } from '@/components/Editor/helpers/Three/fitCameraToObject';
import { DisabledModelParts, MeshData, Size } from '@/components/Editor/types/editor';
import { resizeRendererToDisplaySize } from '@/components/Editor/utils/resizeRendererToDisplaySize';
import {
  defaultCameraFOV,
  initialModelRotationYAxis,
  JPEG_MIME,
} from '@/constants/editor';
import { OrbitControls } from '@/lib/OrbitControls';
import store from '@/store';
import {
  GET_BACKGROUND_COLOR,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_MESH_SELECTION_REMOVED,
  GET_IS_MOBILE,
  GET_IS_MODEL_READY,
  GET_IS_SIDE_SELECTION_ENABLED,
  GET_IS_TEXTURE_UPDATED,
  GET_SINGLE_EDITABLE_MESH,
  GET_TEXTURES_TO_UPDATE,
  PUSH_MESH,
  SET_ACTIVE_MESH,
  SET_IS_EDITOR_LOADING,
  SET_IS_MODEL_READY,
  SET_IS_SIDE_SELECTION_ENABLED,
  SET_IS_TEXTURE_UPDATED,
  SET_MODEL_SIZE_IN_MM,
} from '@/store/Editor/constants';
import { TextureToUpdate } from '@/store/Editor/types';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';
import { Template } from '@/store/Templates/types';
import {
  decrypt3DModel,
  is3DModelEncoded,
  MODEL_BASE64_IDENTIFICATOR,
} from '@/utils/crypto/decrypt3DModel';
import { DEFAULT_BACKGROUND_COLOR } from '../constants/defaultSettings';
import { initCustomFonts } from '../helpers/customFonts/loadCustomFonts';
import { CanvasType } from '../helpers/fabric/canvasModifiers/createCanvas';
import { createCanvasesForAllMeshs } from '../helpers/fabric/canvasModifiers/createCanvasesForAllMeshs';
import { getSizeFromMeshName } from '../helpers/fabric/canvasModifiers/getFabricCanvasSize';
import { checkForSingleEditableMesh } from '../helpers/Three/checkForSingleEditableMesh';
import { getEventPositionOnCanvas } from '../helpers/Three/getCanvasRelativePosition';
import { getObjectSize } from '../helpers/Three/getObjectSize';
import { highlightAllMeshs } from '../helpers/Three/highlightAllMeshs';
import { highlightSelectedMesh } from '../helpers/Three/highlightSelectedMesh';
import { isMeshWithSizeData } from '../helpers/Three/isMeshWithSizeData';
import { prepareSceneForScan } from '../helpers/Three/prepareSceneForScan';
import { removeMeshProperly } from '../helpers/Three/removeMeshProperly';
import { selectMesh } from '../helpers/Three/selectMesh';
import { updateCameraAspectRatio } from '../helpers/Three/updateCameraAspectRatio';

const useScene = (
  props,
  { expose },
): {
  sceneContainer: Ref<HTMLElement | undefined>;
} => {
  let animationFrameId = 0;

  const sceneBackground = toRef(props, 'sceneBackground');
  const isControlsVisible = toRef(props, 'isControlsVisible');

  const sceneContainer = ref(<HTMLElement>{});
  const controls = ref(<OrbitControls>{});
  const scene = ref(<Scene>{});
  const light = ref(<DirectionalLight>{});
  const renderer = ref(<WebGLRenderer>{});
  const camera = ref(<PerspectiveCamera>{});
  const model = ref(<Group>{});
  const meshs = ref(<Mesh[]>[]);

  const isEditorActive = computed(
    (): boolean => store.getters[GET_IS_EDITOR_ACTIVE]);
  const backgroundColor = computed(
    (): string => store.getters[GET_BACKGROUND_COLOR]);
  const rendererSize = computed((): Size => ({
    width: window.outerWidth,
    height: window.outerHeight,
  }));
  const isSideSelectionEnabled = computed(
    (): boolean => store.getters[GET_IS_SIDE_SELECTION_ENABLED]);

  const isMeshSelectionRemoved = computed(
    (): boolean => store.getters[GET_IS_MESH_SELECTION_REMOVED]);
  const template = computed(
    (): Template => store.getters[GET_CURRENT_TEMPLATE],
  );
  const modelLink = computed((): Template['nodes'] => template.value.nodes);
  const isModelReady = computed(
    (): boolean => store.getters[GET_IS_MODEL_READY],
  );
  const texturesToUpdate = computed(
    (): TextureToUpdate[] => store.getters[GET_TEXTURES_TO_UPDATE],
  );
  const isMobile = computed((): boolean => store.getters[GET_IS_MOBILE]);
  const singleEditableMesh = computed(
    (): MeshData | null => store.getters[GET_SINGLE_EDITABLE_MESH],
  );
  const isSideSelectionCanBeEnabled = computed(
    (): boolean => isMobile.value && !singleEditableMesh.value,
  );
  const isTextureUpdated = computed(
    (): boolean => store.getters[GET_IS_TEXTURE_UPDATED],
  );

  const initCamera = (): void => {
    camera.value = new PerspectiveCamera();
    camera.value.fov = defaultCameraFOV;
    camera.value.aspect = window.outerWidth / window.outerHeight;
    camera.value.near = 0.1;
    camera.value.far = 100;
  };

  const initScene = (): void => {
    scene.value = new Scene();
    scene.value.background = sceneBackground.value;
  };

  const generateSceneScan = async () => {
    await prepareSceneForScan(
      camera,
      renderer,
      controls,
      model,
    );
    updateCameraAspectRatio(renderer, camera);
    const tempLight = light.value;
    tempLight.position.copy(camera.value.position);
    controls.value.update();
    renderer.value.clear();
    renderer.value.render(scene.value, camera.value);
    const scan = renderer.value.domElement.toDataURL(JPEG_MIME);
    return scan;
  };

  const initRenderer = (): void => {
    const { height, width } = rendererSize.value;
    renderer.value = new WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    renderer.value.setSize(width, height);
    renderer.value.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.value.outputEncoding = sRGBEncoding;
    renderer.value.shadowMap.enabled = false;
    sceneContainer.value?.appendChild(renderer.value.domElement);
  };
  
  const turnOffControlsAutoRotate = (): void => {
    controls.value.autoRotate = false;
  };

  const initControls = (): void => {
    controls.value = new OrbitControls(camera.value, sceneContainer.value);
    controls.value.enablePan = false;
    controls.value.autoRotate = true;
    controls.value.enableDamping = true;

    controls.value.addEventListener('start', turnOffControlsAutoRotate);
  };

  const initLight = (): void => {
    light.value = new DirectionalLight();
    light.value.color = new Color(0xffffff);
    light.value.intensity = 1;
    scene.value.add(light.value);
  };

  const animate = (): void => {
    const isRendererSizeChanged = resizeRendererToDisplaySize(renderer.value);
    if (isRendererSizeChanged) {
      updateCameraAspectRatio(renderer, camera);
    }
    light.value.position.copy(camera.value.position);
    controls.value.update();
    renderer.value.clear();
    animationFrameId = requestAnimationFrame(animate);
    renderer.value.render(scene.value, camera.value);
  };

  const checkIfMeshDisabled = ({ name, type }): boolean => {
    return type === 'Group'
      || name.includes(DisabledModelParts._disabled)
      || name.includes(DisabledModelParts._outline)
      || name.includes(DisabledModelParts.size)
      || false;
  };

  const setFigure = (): void => {
    const gltfLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    const fileLoader = new FileLoader();

    dracoLoader.setDecoderPath('three/examples/js/libs/draco/gltf');
    gltfLoader.setDRACOLoader(dracoLoader);
    fileLoader.setResponseType('text');

    fileLoader.load(modelLink.value, (data: string | ArrayBuffer): void => {
      const modelData = is3DModelEncoded(data as string)
        ? MODEL_BASE64_IDENTIFICATOR + decrypt3DModel(data as string)
        : modelLink.value;

      gltfLoader.load(modelData, async (obj: GLTF): Promise<void> => {
        await initCustomFonts();
        model.value = obj.scene;
        const children = model.value.children as Mesh[];
  
        children.forEach((item: Mesh): void => {
          const meshData = { name: item.name, size: {}, disabled: false };
          meshData.disabled = checkIfMeshDisabled(item);
          meshs.value.push(item);
          meshData.size = getObjectSize(item);
          store.commit(PUSH_MESH, meshData);
          if (isMeshWithSizeData(meshData.name)) {
            store.commit(
              SET_MODEL_SIZE_IN_MM,
              getSizeFromMeshName(meshData.name),
            );
          }
        });
  
        checkForSingleEditableMesh();
        scene.value.add(model.value);
        model.value.rotation.set(0, initialModelRotationYAxis, 0);
        fitCameraToObject(camera.value, model.value, null, controls.value);
        updateCameraAspectRatio(renderer, camera);
        updateDisabledMeshsColor(DEFAULT_BACKGROUND_COLOR);
        store.commit(SET_IS_MODEL_READY, true);
      });
    });
  };

  const updateMeshMaterial = (
    texture: CanvasTexture,
    meshName: string,
  ): void => {
    const mesh = meshs.value.find(({ name }) => name === meshName);
    if (mesh) {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.anisotropy = 16;
      texture.encoding = GammaEncoding;
      mesh.material = new MeshPhysicalMaterial({
        map: texture,
        side: DoubleSide,
        roughness: 0.95,
      });
      mesh.material.needsUpdate = true;
    }

    texture.dispose();

    if (!isTextureUpdated.value) {
      store.commit(SET_IS_TEXTURE_UPDATED, true);
    }
  };

  const updateDisabledMeshsColor = (color: string): void => {
    const canvas = new fabric.Canvas(CanvasType.colorTexture, {
      height: 1,
      width: 1,
      backgroundColor: singleEditableMesh.value
        ? color
        : DEFAULT_BACKGROUND_COLOR,
    });
    canvas.renderAll();
    const texture = new CanvasTexture(canvas.lowerCanvasEl);

    meshs.value.forEach(mesh => {
      if (checkIfMeshDisabled(mesh))
        updateMeshMaterial(
          texture,
          mesh.name,
        );
    });
    canvas.dispose();
    texture.dispose();
  };

  const selectFigure = (event: MouseEvent | TouchEvent): void => {
    if (!isControlsVisible.value) return;
    const mouse = new Vector2();
    const { x, y } = getEventPositionOnCanvas(event, renderer.value);
    const { clientHeight, clientWidth } = renderer.value.domElement;
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    mouse.x = (x / clientWidth) * 2 - 1;
    mouse.y = (y / clientHeight) * -2 + 1;
    const raycaster = new Raycaster();
    raycaster.setFromCamera(mouse, camera.value);
    const intersects = raycaster.intersectObjects(scene.value.children, true);

    if (intersects.length > 0) {
      selectMesh(intersects[0].object as Mesh, meshs.value);
    }
  };

  const enableControls = (): void => {
    sceneContainer.value.addEventListener('click', selectFigure, false);
    sceneContainer.value.addEventListener('touchstart', selectFigure, false);
  };

  const disableControls = (): void => {
    highlightAllMeshs(meshs.value);
    sceneContainer.value.removeEventListener('click', selectFigure, false);
    sceneContainer.value.removeEventListener('touchstart', selectFigure, false);
  };

  const init = (): void => {
    initCamera();
    initScene();
    initRenderer();
    initControls();
    initLight();
    setFigure();
    animate();
  };

  const removeSceneData = (): void => {
    disableControls();
    controls.value.removeEventListener('start', turnOffControlsAutoRotate);
    cancelAnimationFrame(animationFrameId);
    removeMeshProperly(scene.value, scene.value);
    removeMeshProperly(model.value, scene.value);
    scene.value.dispose();
    scene.value.remove(light.value);
    renderer.value.dispose();
    controls.value.dispose();
    sceneContainer.value?.remove();
  };

  const initMeshSelecting = (): void => {
    if (isSideSelectionCanBeEnabled.value) {
      store.commit(SET_IS_SIDE_SELECTION_ENABLED, true);
    }
    if (singleEditableMesh.value) {
      store.commit(SET_ACTIVE_MESH, singleEditableMesh.value);
    }
  };

  watch(texturesToUpdate, (texturesToUpdate): void => {
    texturesToUpdate.forEach(({ meshName, texture }) => {
      updateMeshMaterial(texture, meshName);
    });
    highlightSelectedMesh(meshs.value);
  }, { deep: true });

  watch(isEditorActive, (isEditorActive): void => {
    isEditorActive
      ? cancelAnimationFrame(animationFrameId)
      : animate();
  });

  watch(isSideSelectionEnabled, (selectionEnabled): void => {
    if (selectionEnabled) {
      highlightSelectedMesh(meshs.value);
      enableControls();
    } else {
      highlightAllMeshs(meshs.value);
      disableControls();
    }
  });

  watch(isMeshSelectionRemoved, (meshSelectionRemoved): void => {
    meshSelectionRemoved 
      ? highlightAllMeshs(meshs.value) 
      : highlightSelectedMesh(meshs.value);
  });

  watch(isModelReady, async () => {
    initMeshSelecting();
    await createCanvasesForAllMeshs();
    store.commit(SET_IS_EDITOR_LOADING, false);
  });

  watch(backgroundColor, (color): void => {
    updateDisabledMeshsColor(color);
  });

  onMounted(init);

  onBeforeUnmount(removeSceneData);

  expose({ generateSceneScan, updateMeshMaterial });

  return {
    sceneContainer,
  };
};

export default useScene;
