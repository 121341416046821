
import { onBeforeUnmount, watch } from 'vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import { useStopScroll } from '@/composables/useStopScroll';
import { isTabletAndLower } from '@/utils/Mobile';

export default {
  components: {
    BaseButton,
  },
  props: {
    contentClass: {
      required: false,
      type: String,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    overrideWithClass: {
      default: 'modal-window',
      required: false,
      type: String,
    },
  },
  setup(props) {
    const { disableScroll, enableScroll } = useStopScroll();
    watch(() => props.isOpen, isOpen => 
      isOpen ? disableScroll() : enableScroll());

    onBeforeUnmount(enableScroll);
    return {
      isTabletAndLower,
    };
  },
};
