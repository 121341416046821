
import useBackToTopButton from './BackToTopButton';

export default {
  props: {
    scrollableElement: {
      required: false,
      type: [ HTMLElement, Boolean ],
    },
  },
  setup(props) {
    return useBackToTopButton(props);
  },
};
