import { throttle } from 'lodash';
import {
  setActiveSelectionId,
} from '@/components/Editor/helpers/fabric/activeObjects/setActiveSelectionId';
import {
  validateActiveObjectProps,
} from '@/components/Editor/helpers/fabric/activeObjects/validateActiveObjectProps';
import {
  modifyDefaultFabricControls,
} from '@/components/Editor/helpers/fabric/controls/modifyDefaultFabricControls';
import {
  lockActiveSelectionWithLockedObject,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import {
  getFontSizeOnResize,
} from '@/components/Editor/helpers/fabric/textObject/getFontSizeOnResize';
import { FabricEvent } from '@/components/Editor/types/fabric';
import {
  activeObjectCenterScaling,
} from '@/components/Editor/utils/activeObjectCenterScaling';
import {
  addDPIToFabricObject,
  setFabricObjectBorderByDpi,
} from '@/utils/dpi';

const calculateDpi = throttle(addDPIToFabricObject, 100);

export const changeActiveObjectPropsOnScaled = (
  { target }: FabricEvent,
): void => {
  validateActiveObjectProps(target);
  addDPIToFabricObject(target.canvas, target);
  setFabricObjectBorderByDpi(target);
};

export const changeActiveObjectPropsOnScaling = (
  { target }: FabricEvent,
): void => {
  validateActiveObjectProps(target);
  calculateDpi(target.canvas, target);
  setFabricObjectBorderByDpi(target);
};

export const changeActiveObjectPropsOnSelected = (
  { target }: FabricEvent,
): void => {
  getFontSizeOnResize({ target });
  lockActiveSelectionWithLockedObject(target);
  setActiveSelectionId(target);
  modifyDefaultFabricControls(target);
  activeObjectCenterScaling(target);
};
