
import useRateTable from './RateTable';
import RateTableItem from './RateTableItem/RateTableItem.vue';

export default {
  components: {
    RateTableItem,
  },
  props: {
    zone: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    return useRateTable(props);
  },
};
