
import useBurgerMenu from '@/components/BurgerMenu/BurgerMenu';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    currentTab: {
      required: true,
      type: String,
    },
    navItems: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useBurgerMenu();
  },
};
