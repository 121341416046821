
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useBaseMenu from './BaseMenu';

export default {
  components: {
    BaseButton,
  },
  props: {
    attach: {
      default: true,
      required: false,
      type: [ String, Boolean ],
    },
    icon: {
      default: 'mdi-dots-horizontal',
      required: false,
      type: String,
    },
    nudgeWidth: {
      default: 170,
      required: false,
      type: Number,
    },
    minWidth: {
      required: false,
      type: [ Number, String ],
    },
  },
  setup() {
    return useBaseMenu();
  },
};
