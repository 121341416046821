
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useApp from './App';

export default {
  components: {
    BaseButton,
  },
  setup() {
    return useApp();
  },
};
