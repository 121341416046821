
import { ref } from 'vue';
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import { ProductInfo } from '@/constants/productInfo';

export default {
  components: {
    BackToTopButton,
    ModalWindow,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    const privacyPolicyModal = ref();
    return {
      ProductInfo,
      privacyPolicyModal,
    };
  },
};
