
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useCollectionsSelectorMobile from './CollectionsSelectorMobile';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  props: {
    collections: {
      required: false,
      type: Array,
    },
    isOpen: {
      required: true,
      type: Boolean,
    },
    selectedCollection: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return useCollectionsSelectorMobile(props, context);
  },
};
