
import BaseIndicator from '@/components/Common/BaseIndicator/BaseIndicator.vue';

export default {
  components: { BaseIndicator },
  props: {
    dpi: {
      required: true,
      type: Number,
    },
    dpiIndicatorColor: {
      required: true,
      type: String,
    },
  },
};
