import { render, staticRenderFns } from "./PrivacyPolicyModal.vue?vue&type=template&id=21017630&"
import script from "./PrivacyPolicyModal.vue?vue&type=script&lang=ts&"
export * from "./PrivacyPolicyModal.vue?vue&type=script&lang=ts&"
import style0 from "./PrivacyPolicyModal.vue?vue&type=style&index=0&id=21017630&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports