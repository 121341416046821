import { computed } from 'vue';
import {
  changeTextFont,
} from '@/components/Editor/helpers/fabric/textObject/changeTextFont';
import { FabricObject } from '@/components/Editor/types/fabric';
import { black54 } from '@/constants/colors';
import store from '@/store';
import { GET_USER_FONTS } from '@/store/Authorization/constants';
import {
  GET_ACTIVE_OBJECT,
  GET_FONTS_TO_RENDER,
  GET_IS_IFRAME,
  SET_IS_FONT_SELECT_OPEN,
  TRIGGER_REMOVE_FONT,
} from '@/store/Editor/constants';

const useFontSelect = () => {
  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );
  const font = computed((): string => activeObject.value?.fontFamily);
  const fontsToRender = computed(
    (): string[] => store.getters[GET_FONTS_TO_RENDER],
  );
  const userFonts = computed((): string[] => {
    return store.getters[GET_USER_FONTS];
  });

  const isIframe = computed(():boolean => store.getters[GET_IS_IFRAME]);

  const toggleFontSelect = (flag: boolean): void => {
    store.commit(SET_IS_FONT_SELECT_OPEN, flag);
  };

  const selectFont = (fontName: string): void => {
    changeTextFont(fontName);
    toggleFontSelect(false);
  };

  const triggerRemoveFont = (fontName: string): void => {
    store.dispatch(TRIGGER_REMOVE_FONT, fontName);
  };

  return {
    black54,
    font,
    fontsToRender,
    isIframe,
    triggerRemoveFont,
    selectFont,
    userFonts,
    toggleFontSelect,
  };
};
  
export default useFontSelect;
