
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import useRemoveFontModal from '@/components/Editor/RemoveFontModal/RemoveFontModal';

export default {
  components: {
    BaseButton,
    ModalWindow,
  },
  setup() {
    return useRemoveFontModal();
  },
};
