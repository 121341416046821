
import AuthWithSocial from '@/components/AuthWithSocial/AuthWithSocial.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal/PrivacyPolicyModal.vue';
import AlreadyHaveAccount from '../AlreadyHaveAccount/AlreadyHaveAccount.vue';
import useSignUpForm from './SignUpForm';

export default {
  components: {
    AlreadyHaveAccount,
    AuthWithSocial,
    BaseButton,
    BaseCheckbox,
    BaseTitle,
    PrivacyPolicyModal,
    TextField,
  },
  setup() {
    return useSignUpForm();
  },
};
