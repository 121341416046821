
import TextField from '@/components/Common/TextField/TextField.vue';
import { black06, primaryViolet } from '@/constants/colors';

export default {
  components: {
    TextField,
  },
  props: {
    fontSize: {
      required: true,
      type: Number,
    },
  },
  setup() {
    return {
      black06,
      primaryViolet,
    };
  },
};
