
import BaseTooltip from '../Common/BaseTooltip/BaseTooltip.vue';
import { useCopyToClickboard } from './CopyToClickBoard';

export default {
  components: { BaseTooltip },
  props: {
    value: {
      type: [ String, Number ],
      required: true,
    },
  },
  setup(props) {
    return useCopyToClickboard(props);
  },
};
