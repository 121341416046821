
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
import SpinnerLoader from '@/components/SpinnerLoader/SpinnerLoader.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useCollections from './Collections';
import CollectionsList from './CollectionsList/CollectionsList.vue';
import CollectionsNotExists from './CollectionsNotExists/CollectionsNotExists.vue';

export default {
  components: {
    BaseButton,
    CollectionsList,
    CollectionsNotExists,
    MainLayout,
    SkeletonLoader,
    SpinnerLoader,
  },
  setup() {
    return useCollections();
  },
};
