
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import useFontSelect from './FontSelect';

export default {
  components: {
    BaseButton,
    BaseIcon,
    TrashcanIcon,
  },
  setup() {
    return useFontSelect();
  },
};
