
import { primaryViolet, whiteSmoke } from '@/constants/colors';
import { baseSelectHeight } from '@/constants/editor';
import useBaseSelect from './BaseSelect';

export default {
  props: {
    attach: {
      required: false,
      type: [ Boolean, String ],
    },
    backgroundColor: {
      default: whiteSmoke,
      required: false,
      type: String,
    },
    color: {
      default: primaryViolet,
      required: false,
      type: String,
    },
    dense: {
      default: true,
      required: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    height: {
      default: baseSelectHeight,
      required: false,
      type: Number,
    },
    hideDetails: {
      default: true,
      required: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
    },
    itemText: {
      required: false,
      type: String,
    },
    itemValue: {
      required: false,
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    menuProps: {
      required: false,
      type: Object,
    },
    placeholder: {
      required: false,
      type: String,
    },
    returnObject: {
      required: false,
      type: Boolean,
    },
    rules: {
      required: false,
      type: Array,
    },
    value: {
      default: '',
      required: true,
      type: [ String, Number, Object ],
    },
  },
  setup() {
    return useBaseSelect();
  },
};
