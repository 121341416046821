import { v4 } from 'uuid';
import { useI18n } from 'vue-i18n-composable';
import { ActionContext } from 'vuex';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import { setObjectsLayerName } from '@/components/Editor/helpers/layers/layerName';
import {
  getCanvasScreenshotFile,
} from '@/components/Editor/utils/canvas';
import { TemplateDesignAPI } from '@/services/TemplateDesignAPI';
import { SET_NOTIFICATION } from '@/store/constants';
import { UPDATE_CANVAS_DATA } from '@/store/Templates/constants';
import { NotificationType } from '@/types/notification';
import { handleResponse } from '@/utils/axios/handleResponse';
import {
  ADD_TEMPLATE_DESIGN,
  APPLY_DESIGN,
  EDIT_DESIGN,
  GET_TEMPLATE_DESIGNS,
  REMOVE_TEMPLATE_DESIGN,
  SAVE_DESIGN,
  SET_ACTIVE_OBJECT,
  SET_BACKGROUND_COLOR,
  SET_FABRIC_OBJECTS,
  SET_IS_APPLYING_DESIGN,
  SET_IS_SAVE_DESIGN_DISABLED,
  SET_LAYER_NUMBER,
  SET_TEMPLATE_DESIGNS,
  UPDATE_CANVAS_HISTORY,
  UPDATE_TEMPLATE_DESIGN,
} from '../constants';
import { EditorState, TemplateDesign } from '../types';

export const templateDesignActions = {
  [REMOVE_TEMPLATE_DESIGN]: async (
    { commit }: ActionContext<EditorState, any>,
    { templateDesignId }: {templateDesignId: number },
  ): Promise<void> => {
    const response = await TemplateDesignAPI.deleteTemplateDesign({
      templateDesignId,
    });

    const handleSuccessStatus = () => 
      commit(REMOVE_TEMPLATE_DESIGN, templateDesignId);

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 204,
    });    
  },
  [SAVE_DESIGN]: async (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    { name }: { name: string },
  ): Promise<void> => {
    const { t } = useI18n();
    const { activeCanvas, canvasState } = state;
    
    activeCanvas.discardActiveObject().renderAll();

    const background = canvasState.backgroundColor;
    const img = await getCanvasScreenshotFile(activeCanvas, 'screenshot.jpg');

    if (!img) {
      dispatch(SET_NOTIFICATION, {
        text: t('redactor.cannotMakeCanvasScreenshot'),
        type: NotificationType.Error,
      });
      return; 
    }
    const objects = canvasState.fabricObjects
      .filter(({ id }) => id !== BACKGROUND_OBJECT_ID)
      .map(obj => ({ ...obj, id: v4() }));

    const response = await TemplateDesignAPI.createTemplateDesign({
      background,
      name,
      objects,
      img,
    });

    const handleSuccessStatus = ({ templateDesign }) => 
      commit(ADD_TEMPLATE_DESIGN, templateDesign);

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 201,
    });
    commit(SET_IS_SAVE_DESIGN_DISABLED, true);
  },
  [SET_IS_APPLYING_DESIGN]: async (
    { state }: ActionContext<EditorState, any>,
    isApplyingDesign: boolean,
  ): Promise<void> => {
    state.editorConfigs.isApplyingDesign = isApplyingDesign;
  },
  [APPLY_DESIGN]: async (
    { commit, dispatch, state }: ActionContext<EditorState, any>,
    { templateDesign }: { templateDesign: TemplateDesign },
  ): Promise<void> => {
    dispatch(SET_IS_APPLYING_DESIGN, true);
    dispatch(UPDATE_CANVAS_HISTORY);
    const objects = JSON.parse(templateDesign.objects)
      .map(obj => ({ ...obj, id: v4() }));
    setObjectsLayerName(objects);
    const layerCounters = objects.map(({ layerNumber }) => layerNumber);
    const maxLayerCounter = layerCounters?.length
      ? Math.max(...layerCounters)
      : 0;
    const background = state.canvasState.fabricObjects.find(
      ({ id }) => id === BACKGROUND_OBJECT_ID,
    );
    commit(SET_FABRIC_OBJECTS, [ background, ...objects ]);
    commit(SET_BACKGROUND_COLOR, templateDesign.background);
    commit(SET_ACTIVE_OBJECT, null);
    commit(SET_LAYER_NUMBER, maxLayerCounter);
    commit(UPDATE_CANVAS_DATA);
  },
  [GET_TEMPLATE_DESIGNS]: async (
    { commit }: ActionContext<EditorState, any>,
    { name }: { name?: TemplateDesign['name'] },
  ): Promise<void> => {
    const response = await TemplateDesignAPI.getTemplateDesigns({ name });

    const handleSuccessStatus = (
      { templateDesigns }: { templateDesigns: TemplateDesign[] },
    ) => {
      commit(SET_TEMPLATE_DESIGNS, templateDesigns);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
  [EDIT_DESIGN]: async (
    { commit }: ActionContext<EditorState, any>,
    { name, templateDesignId }: 
    { name: string, templateDesignId: number },
  ): Promise<void> => {
    const response = await TemplateDesignAPI.editTemplateDesign({
      name, templateDesignId,
    });

    const handleSuccessStatus = (
      { templateDesign }: { templateDesign: TemplateDesign },
    ) => {
      commit(UPDATE_TEMPLATE_DESIGN, templateDesign);
    };

    handleResponse({
      handleSuccessStatus,
      response,
      successStatus: 200,
    });
  },
};
