
import useBaseButton from '@/components/Common/BaseButton/BaseButton';
import { black87 } from '@/constants/colors';

export default {
  props: {
    applyColorToText: {
      required: false,
      type: Boolean,
    },
    buttonText: {
      required: false,
      type: String,
    },
    color: {
      default: black87,
      required: false,
      type: String,
    },
    dark: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    fab: {
      required: false,
      type: Boolean,
    },
    hasBackground: {
      default: false,
      required: false,
      type: Boolean,
    },
    height: {
      required: false,
      type: [ String, Number ],
    },
    icon: {
      required: false,
      type: String,
    },
    iconSize: {
      required: false,
      type: [ Number, String ],
    },
    isLoading: {
      required: false,
      type: Boolean,
    },
    ripple: {
      type: Boolean,
      default: true,
    },
    large: {
      required: false,
      type: Boolean,
    },
    light: {
      required: false,
      type: Boolean,
    },
    noMarginTop: {
      required: false,
      type: Boolean,
    },
    outlined: {
      required: false,
      type: Boolean,
    },
    plain: {
      required: false,
      type: Boolean,
    },
    prependIcon: {
      required: false,
      type: String,
    },
    svgIcon: {
      required: false,
      type: Boolean,
    },
    tile: {
      required: false,
      type: Boolean,
    },
    type: {
      default: 'button',
      required: false,
      type: String,
    },
    vActivatorEmits: {
      type: Object,
    },
    vActivatorProps: {
      type: Object,
    },
    width: {
      required: false,
      type: [ String, Number ],
    },
  },
  setup(props) {
    return useBaseButton(props);
  },
};
