import CanvasBGPattern from '@/components/Editor/assets/canvasBGPattern.png';
import { FabricCanvas } from '@/components/Editor/types/fabric';

export const addCanvasBackgroundPattern = (
  canvas: FabricCanvas,
): void => {
  canvas.setBackgroundColor({
    source: CanvasBGPattern,
  }, canvas.renderAll.bind(canvas));
};
