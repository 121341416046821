
import useAlignmentIcon from './TextAlignIcons';

export default {
  props: {
    alignmentType: {
      required: true,
      type: String,
    },
    textAlignment: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return useAlignmentIcon(props);
  },
};
