
import { PropType } from 'vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import TrashcanIcon from '@/components/icons/TrashcanIcon.vue';
import { TemplateDesign } from '@/store/Editor/types';
import { useTemplateDesignCard } from './TemplateDesignCard';
export default {
  components: { BaseButton, TrashcanIcon, BaseIcon, TextField },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    templateDesign: {
      type: Object as PropType<TemplateDesign>,
      required: true,
    },
  },
  setup(props, context) {
    return useTemplateDesignCard(props, context);
  },
};
