
import { DEFAULT_FONT_SIZES } from '@/components/Editor/constants/defaultConfigs';

export default {
  props: {
    fontSize: {
      required: true,
      type: Number,
    },
  },
  setup() {
    return { DEFAULT_FONT_SIZES };
  },
};
