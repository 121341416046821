
import { black54, black60, secondaryViolet } from '@/constants/colors';

export default {
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    pageCount: {
      type: Number,
    },
    page: {
      type: Number,
    },
    paginationPageCount: {
      type: Number,
      default: 7,
    },
  },
  setup() {
    return {
      secondaryViolet,
      black54,
      black60,
    };
  },
};
