
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useCreatePasswordForm from './CreatePasswordForm';

export default {
  components: {
    BaseButton,
    BaseTitle,
    TextField,
  },
  setup() {
    return useCreatePasswordForm();
  },
};
