import { FabricObject } from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_LAYER_NUMBER,
  SET_LAYER_NUMBER,
} from '@/store/Editor/constants';
import { CanvasDataByMesh } from '@/store/Editor/types';
import { GET_CURRENT_TEMPLATE } from '@/store/Templates/constants';
import { LayerNumberModes } from './types';

export const getDefaultLayerNumber = (): number => {
  const canvasDataByMesh = store.getters[GET_CURRENT_TEMPLATE].canvasData;
  if (canvasDataByMesh) {
    const allLayerNumbers = canvasDataByMesh
      .map((data: CanvasDataByMesh): number[] => {
        return (data.canvasData as FabricObject[])
          .map((object: FabricObject): number => object.layerNumber);
      })
      .flat();
    return Math.max(...allLayerNumbers);
  } else return 0;
};

export const increaseLayerNumber = (): number => {
  const layerNumber = store.getters[GET_LAYER_NUMBER];
  return layerNumber + 1;
};

export const updateLayerNumber = (mode: LayerNumberModes): number => {
  switch (mode) {
  case LayerNumberModes.default:
    return getDefaultLayerNumber();
  case LayerNumberModes.increase:
    return increaseLayerNumber();
  default: return 0;
  }
};

export const updateLayerNumberForNewObjects = (
  mode: LayerNumberModes,
): number => {
  const newLayerNumber = updateLayerNumber(mode);
  store.commit(SET_LAYER_NUMBER, newLayerNumber);
  return newLayerNumber;
};
