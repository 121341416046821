
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseCheckbox from '@/components/Common/BaseCheckbox/BaseCheckbox.vue';
import ModalWindow from '@/components/Common/ModalWindow/ModalWindow.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useShippingZoneModal from './ShippingZoneModal';

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    ModalWindow,
    TextField,
  },
  props: {
    deliveryProfile: {
      required: true,
      type: Object,
    },
    isModalOpen: {
      required: true,
      type: Boolean,
    },
    zoneToEdit: {
      required: false,
      type: Object,
    },
  },
  setup(props, context) {
    return useShippingZoneModal(props, context);
  },
};
