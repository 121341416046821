import { fabric } from 'fabric';
import { OBJECT_ATTRIBUTES_TO_EXCLUDE } from '@/components/Editor/constants/customConfigs';
import { DEFAULT_IMAGE_SETTINGS } from '@/components/Editor/constants/defaultConfigs';
import { Size } from '@/components/Editor/types/editor';
import { FabricObject, FabricObjectId } from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_IS_APPLYING_DESIGN,
  GET_IS_LOADED_FROM_HISTORY,
  GET_ZOOM_TO_DEFAULT,
} from '@/store/Editor/constants';
import { loadImage } from '@/utils/loadImage';
import { centerObjectPosition } from '../objectModifiers/centerObjectPosition';
import { fitImageToCanvas } from './fitImageToCanvas';

export const createFabricImage = async ({
  applyCanvasZoom,
  canvasSize,
  imageSettings,
  isCanvasRestored,
}: {
  applyCanvasZoom?: boolean,
  canvasSize: Size,
  imageSettings: typeof DEFAULT_IMAGE_SETTINGS | FabricObject,
  isCanvasRestored: boolean,
}): Promise<FabricObject> => {
  const canvasZoomForDefault = applyCanvasZoom
    ? store.getters[GET_ZOOM_TO_DEFAULT]
    : 1;
  const isLoadedFromHistory = store.getters[GET_IS_LOADED_FROM_HISTORY];
  const isApplyingDesign = store.getters[GET_IS_APPLYING_DESIGN];

  const shouldFitToCanvas = !isLoadedFromHistory
    && !isCanvasRestored
    && !isApplyingDesign;

  const { scaleX, scaleY } = imageSettings;

  let fitScaleX = scaleX;
  let fitScaleY = scaleY;

  const imageAsHtml = await loadImage(imageSettings.src);

  if (shouldFitToCanvas) {
    const { scaleX, scaleY } = fitImageToCanvas({
      imageAsHtml,
      imageSettings,
      canvasSize,
      canvasZoomForDefault,
    });
    fitScaleX = scaleX;
    fitScaleY = scaleY;
  }

  return new fabric.Image(imageAsHtml, {
    ...imageSettings,
    scaleX: fitScaleX,
    scaleY: fitScaleY,
    ...OBJECT_ATTRIBUTES_TO_EXCLUDE,
  });
};

export const prepareDataForFabricImage = ({
  canvasSize,
  id,
  layerName,
  layerNumber,
  src,
  zoomToDefault,
}: {
  canvasSize: Size,
  id: FabricObjectId,
  layerName: string,
  layerNumber: number,
  src: string,
  zoomToDefault: number,
}): FabricObject => {
  return {
    ...DEFAULT_IMAGE_SETTINGS,
    id,
    layerNumber,
    layerName,
    ...centerObjectPosition(canvasSize, zoomToDefault),
    src,
  };
};
