
import AuthWithSocial from '@/components/AuthWithSocial/AuthWithSocial.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTitle from '@/components/Common/BaseTitle/BaseTitle.vue';
import DontHaveAccount from '@/components/Common/DontHaveAccount/DontHaveAccount.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import useSignInForm from './SignInForm';

export default {
  components: {
    AuthWithSocial,
    BaseButton,
    BaseTitle,
    DontHaveAccount,
    TextField,
  },
  setup() {
    return useSignInForm();
  },
};
