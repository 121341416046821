
import downloadIcon from '@/assets/icons/download-icon.svg';
import shopifyIcon from '@/assets/icons/shopify-icon.svg';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseTooltip from '@/components/Common/BaseTooltip/BaseTooltip.vue';
import TextField from '@/components/Common/TextField/TextField.vue';
import UndoRedoIcons from '@/components/Editor/UndoRedoIcons/UndoRedoIcons.vue';
import { CollectionRouteTypes } from '@/views/Collections/types';

export default {
  components: {
    BaseButton,
    BaseTooltip,
    TextField,
    UndoRedoIcons,
  },
  props: {
    isNameEditing: {
      required: true,
      type: Boolean,
    },
    isPublic: {
      required: true,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    isSaving: {
      required: true,
      type: Boolean,
    },
    isScanLoading: {
      required: true,
      type: Boolean,
    },
    isShopifyLoading: {
      required: true,
      type: Boolean,
    },
    isValidName: {
      required: true,
      type: Boolean,
    },
    templateName: {
      type: String,
    },
    templateNameRules: {
      required: true,
      type: Array,
    },
    white50: {
      required: true,
      type: String,
    },
  },
  setup() {
    const ignorableClickOutsideElements = [document.querySelector('.global-alert')];
    return {
      downloadIcon,
      ignorableClickOutsideElements,
      shopifyIcon,
      CollectionRouteTypes,
    };
  },
};
