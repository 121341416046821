import { fabric } from 'fabric';
import { computed, onMounted, ref, watch } from 'vue';
import {
  changeTextFont,
} from '@/components/Editor/helpers/fabric/textObject/changeTextFont';
import { ColorPickerType } from '@/components/Editor/types/editor';
import { FabricCanvas } from '@/components/Editor/types/fabric';
import { black54 } from '@/constants/colors';
import store from '@/store';
import { GET_USER_FONTS } from '@/store/Authorization/constants';
import {
  GET_ACTIVE_CANVAS,
  GET_FONTS_TO_RENDER,
  TRIGGER_REMOVE_FONT,
} from '@/store/Editor/constants';
import { isiOS } from '@/utils/isiOS';

const useTextSettingsMobile = props => {
  const isTextAlignmentsActive = ref(false);
  const isAlignmentsActive = ref(false);
  const isRotationActive = ref(false);
  const isFontSizeActive = ref(false);
  const textControls = ref(null as Element | null);
  const fontsToRender = computed(
    (): string[] => store.getters[GET_FONTS_TO_RENDER],
  );
  const activeCanvas = computed(
    (): FabricCanvas => store.getters[GET_ACTIVE_CANVAS],
  );
  const userFonts = computed((): string[] => store.getters[GET_USER_FONTS]);
  
  const exitTextEditingMode = (canvas: fabric.Canvas): void => {
    const activeObject = canvas.getActiveObject();
    if (activeObject.isEditing) activeObject.exitEditing();
  };

  const toggleTextAlignments = (): void => {
    isTextAlignmentsActive.value = !isTextAlignmentsActive.value;
  };

  const closeTextAlignments = (): void => {
    isTextAlignmentsActive.value = false;
  };

  const toggleAlignments = (): void => {
    isAlignmentsActive.value = !isAlignmentsActive.value;
  };

  const closeAlignments = (): void => {
    isAlignmentsActive.value = false;
  };

  const toggleRotation = (): void => {
    isRotationActive.value = !isRotationActive.value;
  };

  const closeRotation = (): void => {
    isRotationActive.value = false;
  };

  const toggleFontSize = (): void => {
    isFontSizeActive.value = !isFontSizeActive.value;
  };

  const closeFontSize = (): void => {
    isFontSizeActive.value = false;
  };

  const scrollTextSettingsPanel = (): void => {
    if (textControls.value) {
      textControls.value.scrollLeft += textControls.value.scrollWidth / 10;
    }
  };

  const closeTextSettingByClickingOnCanvas = (
    flag: boolean,
    closeSetting: (e: Event) => void,
  ): void => {
    const canvasContainer = document.querySelector('.canvas-container')!;
    flag
      ? canvasContainer.addEventListener('touchend', closeSetting)
      : canvasContainer.removeEventListener('touchend', closeSetting);
  };

  const openRemoveFontModal = (fontName: string): void => {
    store.dispatch(TRIGGER_REMOVE_FONT, fontName);
  };

  onMounted(scrollTextSettingsPanel);

  watch(isTextAlignmentsActive, (flag: boolean): void => {
    if (flag) exitTextEditingMode(activeCanvas.value);
    closeTextSettingByClickingOnCanvas(flag, closeTextAlignments);
  });

  watch(isAlignmentsActive, (flag: boolean): void => {
    if (flag) exitTextEditingMode(activeCanvas.value);
    closeTextSettingByClickingOnCanvas(flag, closeAlignments);
  });

  watch(isRotationActive, (flag: boolean): void => {
    if (flag) exitTextEditingMode(activeCanvas.value);
    closeTextSettingByClickingOnCanvas(flag, closeRotation);
  });

  watch(isFontSizeActive, (flag: boolean): void => {
    if (flag) exitTextEditingMode(activeCanvas.value);
    closeTextSettingByClickingOnCanvas(flag, closeFontSize);
  });

  return {
    black54,
    closeAlignments,
    closeFontSize,
    closeRotation,
    closeTextAlignments,
    ColorPickerType,
    fontsToRender,
    isAlignmentsActive,
    isFontSizeActive,
    isiOS,
    isRotationActive,
    isTextAlignmentsActive,
    textControls,
    toggleAlignments,
    toggleFontSize,
    toggleRotation,
    toggleTextAlignments,
    userFonts,
    changeTextFont,
    openRemoveFontModal,
  };
};

export default useTextSettingsMobile;
