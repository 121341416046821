import lodash from 'lodash';
import { computed, ref, watch } from 'vue';
import { BACKGROUND_OBJECT_ID } from '@/components/Editor/constants/defaultConfigs';
import {
  changeActiveObject,
} from '@/components/Editor/helpers/fabric/activeObjects/changeActiveObject';
import {
  changeActiveSelection,
} from '@/components/Editor/helpers/fabric/activeObjects/changeActiveSelection';
import { toggleCanvasListeners } from '@/components/Editor/helpers/fabric/canvasListeners';
import {
  CanvasType,
  createCanvas,
} from '@/components/Editor/helpers/fabric/canvasModifiers/createCanvas';
import { MeshData } from '@/components/Editor/types/editor';
import {
  FabricCanvas,
  FabricObject,
  FabricObjectAttributes,
  FabricObjectType,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  CLEAR_CANVAS_STATE,
  GET_ACTIVE_CANVAS,
  GET_ACTIVE_MESH,
  GET_ACTIVE_OBJECT,
  GET_BACKGROUND_COLOR,
  GET_FABRIC_OBJECTS,
  GET_IS_APPLYING_DESIGN,
  GET_IS_EDITOR_ACTIVE,
  GET_IS_LOADED_FROM_HISTORY,
  GET_IS_OBJECTS_RESTORED,
  SET_INITIAL_EDITOR_DATA,
  SET_IS_APPLYING_DESIGN,
  SET_IS_SAVE_DESIGN_DISABLED,
} from '@/store/Editor/constants';
import { ToggleTypes } from '@/types';
import { loadCustomFonts } from '../helpers/customFonts/loadCustomFonts';
import { createHTMLCanvas } from '../helpers/fabric/canvasModifiers/createHTMLCanvas';
import { disposeCanvas } from '../helpers/fabric/canvasModifiers/disposeCanvas';
import {
  addNewImageOnCanvas,
} from '../helpers/fabric/imageObject/addNewImageOnCanvas';
import {
  isFabricActiveSelection,
  isFabricImage,
  isFabricText,
} from '../helpers/fabric/objectModifiers/checkObjectType';
import { deleteFabricObject } from '../helpers/fabric/objectModifiers/deleteFabricObject';
import { getObjectsFonts } from '../helpers/fabric/objectModifiers/getObjectsFonts';
import { isObjectOnCanvas } from '../helpers/fabric/objectModifiers/isObjectOnCanvas';
import { addNewTextOnCanvas } from '../helpers/fabric/textObject/addNewTextOnCanvas';

export const useEditorCanvas = () => {
  const canvasContainer = ref<HTMLDivElement>();

  const activeMesh = computed((): MeshData => store.getters[GET_ACTIVE_MESH]);
  const activeCanvas = computed(
    (): FabricCanvas => store.getters[GET_ACTIVE_CANVAS],
  );
  const activeObject = computed((): string => store.getters[GET_ACTIVE_OBJECT]);
  const isLoadedFromHistory = computed(
    (): boolean => store.getters[GET_IS_LOADED_FROM_HISTORY],
  );
  const isObjectsRestored = computed(
    (): boolean => store.getters[GET_IS_OBJECTS_RESTORED],
  );
  const backgroundColor = computed(
    (): string => store.getters[GET_BACKGROUND_COLOR],
  );
  const fabricObjects = computed(
    (): FabricObject[] => store.getters[GET_FABRIC_OBJECTS],
  );
  const isEditorActive = computed(
    (): boolean => store.getters[GET_IS_EDITOR_ACTIVE],
  );

  const changeBackgroundColor = (): void => {
    if (isEditorActive.value) {
      store.commit(SET_IS_SAVE_DESIGN_DISABLED, false);
      activeCanvas.value
        .getObjects()
        .find(({ id }: FabricObject): boolean => {
          return id === BACKGROUND_OBJECT_ID;
        })
        ?.set({ fill: backgroundColor.value });
      activeCanvas.value.renderAll();
    }
  };

  const setActiveObject = (
    [ currentActiveObject, previousActiveObject ]:
    [FabricObject, FabricObject],
  ): void => {
    if (isEditorActive.value && isObjectsRestored.value) {
      if (currentActiveObject) {
        switch (true) {
        case isFabricActiveSelection(currentActiveObject):
          changeActiveSelection(
            currentActiveObject,
            previousActiveObject,
            activeCanvas.value,
            isLoadedFromHistory.value,
          );
          break;
        case isFabricImage(currentActiveObject):
        case isFabricText(currentActiveObject):
          changeActiveObject({
            currentActiveObject,
            previousActiveObject,
            canvas: activeCanvas.value,
          });
          break;
        default: break;
        }
      } else {
        activeCanvas.value.discardActiveObject();
        activeCanvas.value.renderAll();
      }
    }
  };

  const triggerAddObjects = async (
    objectsToAdd: FabricObject[],
  ): Promise<void> => {
    for (const objectToAdd of objectsToAdd) {
      if (isObjectOnCanvas(activeCanvas.value, objectToAdd)) return;
      if (objectToAdd.type === FabricObjectType.text) {
        addNewTextOnCanvas(objectToAdd);
      }
      if (objectToAdd.type === FabricObjectType.image) {
        await addNewImageOnCanvas(objectToAdd);
      }
    }
  };

  const triggerDeleteObjects = (objectsToDelete: object[]): void => {
    for (const objectToDelete of objectsToDelete) {
      objectToDelete && deleteFabricObject(null, { target: objectToDelete });
    }
  };

  const reorderObjects = (objects: FabricObject[]) => {
    const canvasObjects = activeCanvas.value.getObjects();
    objects
      .filter(({ id }) => id !== BACKGROUND_OBJECT_ID)
      .forEach((object, index) => {
        const canvasObjectIndex = canvasObjects.findIndex(
          ({ id }) => id === object.id,
        );
        const isPositionChanged =
          canvasObjectIndex !== index && canvasObjectIndex > 0;
        if (!isPositionChanged) return;
        activeCanvas.value.moveTo(
          canvasObjects[canvasObjectIndex],
          index + 1,
        );
      });
  };

  const addOrDeleteObject = async (
    currentState: FabricObject[],
    previousState: FabricObject[],
  ): Promise<void> => {
    const objectsToAdd = lodash.differenceBy(
      currentState,
      previousState,
      FabricObjectAttributes.id,
    );
    const objectsToDelete = lodash.differenceBy(
      previousState,
      currentState,
      FabricObjectAttributes.id,
    ).filter(({ id }) => id !== BACKGROUND_OBJECT_ID);
  
    if (objectsToDelete?.length) triggerDeleteObjects(objectsToDelete);
    if (objectsToAdd?.length) await triggerAddObjects(objectsToAdd);
    else reorderObjects(currentState);
    activeCanvas.value.renderAll();
  };

  const detectCanvasChange = ({
    currentActiveObject,
    currentFabricObjects,
    previousActiveObject,
    previousFabricObjects,
  }): boolean => {
    const areObjectsChanged = previousFabricObjects.length
      !== currentFabricObjects.length;
    const isCurrentActiveObjectChanged = previousActiveObject?.id
      === currentActiveObject?.id;
    return areObjectsChanged || isCurrentActiveObjectChanged;
  };

  const discardObjectSelection = (): void => {
    activeCanvas.value.discardActiveObject().renderAll();
  };

  const createCanvasForActiveMesh = async (): Promise<void> => {
    if (activeCanvas.value?.getObjects) {
      activeCanvas.value._discardActiveObject();
      store.commit(CLEAR_CANVAS_STATE);
      disposeCanvas(activeCanvas.value);
    }
    if (canvasContainer.value) {
      createHTMLCanvas(canvasContainer.value, activeMesh.value.name);
    }
    const canvas = await createCanvas(activeMesh.value, CanvasType.render);
    toggleCanvasListeners(canvas, ToggleTypes.on);
    store.dispatch(SET_INITIAL_EDITOR_DATA, canvas);
  };

  watch(backgroundColor, changeBackgroundColor);
  
  watch([ fabricObjects, activeObject ], async (
    [ currentFabricObjects, currentActiveObject ],
    [ previousFabricObjects, previousActiveObject ],
  ): Promise<void> => {
    if (isEditorActive.value) {
      const fonts = getObjectsFonts(currentFabricObjects);
      await loadCustomFonts(fonts);
      const isCanvasChanged = detectCanvasChange({
        previousFabricObjects,
        currentFabricObjects,
        previousActiveObject,
        currentActiveObject,
      });
      if (isCanvasChanged) store.commit(SET_IS_SAVE_DESIGN_DISABLED, false);
      await addOrDeleteObject(currentFabricObjects, previousFabricObjects);
      const canvasIds = activeCanvas.value.getObjects().map(({ id }) => id);
      const currentObjectIds = fabricObjects.value.map(({ id }) => id);
      const isCanvasStateSynced = lodash.isEqual(canvasIds, currentObjectIds);
      const isApplyingDesign = store.getters[GET_IS_APPLYING_DESIGN];
      if (isCanvasStateSynced && isApplyingDesign) 
        store.dispatch(SET_IS_APPLYING_DESIGN, false);
    }
  });

  watch([ activeObject, isObjectsRestored ], setActiveObject);

  watch(activeMesh, createCanvasForActiveMesh);

  return {
    discardObjectSelection,
    canvasContainer,
    CanvasType,
  };
};
