
import closeIcon from '@/components/Editor/assets/icons/closeIcon.svg';
import info from '@/components/Editor/assets/icons/info.svg';

export default {
  props: {
    isEditorActive: {
      required: true,
      type: Boolean,
    },
    noteVisible: {
      required: true,
      type: Boolean,
    },
  },
  setup() {
    return {
      closeIcon,
      info,
    };
  },
};
