
import AuthLayout from '@/layouts/AuthLayout/AuthLayout.vue';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm.vue';

export default {
  components: {
    AuthLayout,
    ForgotPasswordForm,
  },
};
