
export default {
  props: {
    tabs: {
      required: true,
      type: Array,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
