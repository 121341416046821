
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useProfileCard from './ProfileCard';
import ProfileCardModal from './ProfileCardModal/ProfileCardModal.vue';

export default {
  components: {
    BaseButton,
    ProfileCardModal,
  },
  setup() {
    return useProfileCard();
  },
};
