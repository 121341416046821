import { computed, reactive, toRef } from 'vue';
import { ColorPickerType } from '@/components/Editor/types/editor';
import {
  FabricObject,
} from '@/components/Editor/types/fabric';
import store from '@/store';
import {
  GET_ACTIVE_OBJECT,
  GET_IS_IFRAME,
  SET_IS_ADD_FONT_MODAL_OPEN,
  TRIGGER_DELETE_OBJECT,
} from '@/store/Editor/constants';

const useTextSettings = (props, { emit }) => {
  const activeObject = computed(
    (): FabricObject => store.getters[GET_ACTIVE_OBJECT],
  );
  const font = computed((): string => activeObject.value?.fontFamily);
  const isIframe = computed(():boolean => store.getters[GET_IS_IFRAME]);

  const textSettingsProps = reactive({
    ColorPickerType,
    font,
    isCustomizationFrame: toRef(props, 'isCustomizationFrame'),
    isIframe,
    isMobile: toRef(props, 'isMobile'),
    lockInfo: toRef(props, 'lockInfo'),
  });

  const textSettingsEmits = {
    deleteSelectedObject: (): void => {
      store.dispatch(TRIGGER_DELETE_OBJECT);
    },
    openAddFontModal: (): void => {
      store.commit(SET_IS_ADD_FONT_MODAL_OPEN, true);
    },
  };

  return {
    textSettingsEmits,
    textSettingsProps,
  };
};

export default useTextSettings;
