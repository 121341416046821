
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import useTemplatesNotExists from './TemplatesNotExists';
export default {
  components: {
    BaseButton,
  },
  setup() {
    return useTemplatesNotExists();
  },
};
