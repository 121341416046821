import { fabric } from 'fabric';
import { FabricControl } from '@/components/Editor/types/fabric';
import { actualCanvasIconSize, renderRotationIcon } from './renderIcons';

export const getRotationControl = (): FabricControl => {
  const canvasIconSize = actualCanvasIconSize();

  return new fabric.Control({
    actionHandler: fabric.controlsUtils.rotationWithSnapping,
    actionName: 'rotate',
    cursorStyle: 'pointer',
    offsetY: -canvasIconSize,
    render: renderRotationIcon,
    sizeX: canvasIconSize,
    sizeY: canvasIconSize,
    x: 0,
    y: -0.5,
  });
};
