
import AddToMyCollectionsModal from '@/components/AddToMyCollectionsModal/AddToMyCollectionsModal.vue';
import EditDetailsWindow from '@/components/EditDetailsWindow/EditDetailsWindow.vue';
import ConfirmRemoveTemplateModal from './ConfirmRemoveTemplateModal/ConfirmRemoveTemplateModal.vue';
import TemplateCard from './TemplateCard/TemplateCard.vue';
import useTemplatesList from './TemplatesList';

export default {
  components: {
    AddToMyCollectionsModal,
    ConfirmRemoveTemplateModal,
    EditDetailsWindow,
    TemplateCard,
  },
  props: {
    collection: {
      required: true,
      type: Object,
    },
    isEditingRights: {
      required: false,
      type: Boolean,
    },
    isLoading: {
      required: true,
      type: Boolean,
    },
    templates: {
      required: true,
      type: Array,
    },
  },
  setup() {
    return useTemplatesList();
  },
};
