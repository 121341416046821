import { fabric } from 'fabric';
import {
  isObjectLocked,
} from '@/components/Editor/helpers/fabric/lockObjects/lockObject';
import { FabricCanvas, FabricEvent, FabricObject } from '@/components/Editor/types/fabric';
import { primaryViolet } from '@/constants/colors';
import store from '@/store';
import { GET_ZOOM_TO_DEFAULT } from '@/store/Editor/constants';
import { initialVisibleGuidelines } from './constants';
import {
  getVisibleGuidelines,
  isHorizontalIntersects,
  isVerticalIntersects,
  snapHorizontalObject,
  snapVerticalObject,
} from './helpers';
import {
  DrawableGuidelines,
  EditorGuidelines,
  EditorGuidelinesVisibility,
  EditorHorizontalGuidelinesDirection,
  EditorHorizontalLinesNames,
  EditorVerticalGuidelinesDirection,
  EditorVerticalLinesNames,
  Guideline,
  LineDots,
} from './types';

export const addGuidelines = (
  { target }: FabricEvent,
): void => {
  if (!target) return;
  const { canvas } = target;
  const canvasZoomForDefault = store.getters[GET_ZOOM_TO_DEFAULT];
  const activeObject = canvas.getActiveObject();
  if (!activeObject) return;
  if (isObjectLocked(activeObject)) return;
  if (activeObject?.type === 'activeSelection') return;

  const lineHeight = canvas.height / canvasZoomForDefault;
  const lineWidth = canvas.width / canvasZoomForDefault;

  const objects = getObjectsWithGuidelines(canvas);

  const canvasGuidelines = generateGuidelines({
    boundingRect: {
      height: canvas.height / canvasZoomForDefault,
      left: 0,
      top: 0,
      width: canvas.width / canvasZoomForDefault,
    },
    lineHeight,
    lineWidth,
  });
  canvas.guidelines = createGuidelines({
    canvas,
    guidelines: canvasGuidelines,
  });
  checkGuidelinesIntersection(canvas.guidelines, activeObject);

  objects.forEach(obj => {
    const guidelines = generateGuidelines({
      boundingRect: obj.getBoundingRect(true),
      lineHeight,
      lineWidth,
    });
    obj.guidelines = createGuidelines({
      canvas,
      dashed: true,
      guidelines,
    });
    if (obj === activeObject) return;

    checkGuidelinesIntersection(obj.guidelines, activeObject);
  });
};

export const drawGuidelines = (
  { target: { canvas }}: FabricEvent,
): void => {
  const activeObject = canvas.getActiveObject();
  if (!activeObject) return;
  if (activeObject?.type === 'activeSelection') return;

  checkGuidelinesIntersection(canvas.guidelines, activeObject);
  const objects = getObjectsWithGuidelines(canvas);
  objects.forEach(obj => {
    if (obj === activeObject) return;

    checkGuidelinesIntersection(obj.guidelines, activeObject);
  });
};

export const removeCanvasGuidelines = (
  { target }: FabricEvent,
): void => {
  if (target) {
    const { canvas } = target;
    if (canvas.guidelines) removeGuidelines(canvas, canvas.guidelines);
    const objects = getObjectsWithGuidelines(canvas);
    objects.forEach(({ guidelines }) => {
      if (!guidelines) return;
      removeGuidelines(canvas, guidelines);
    });
  }
};

export const getObjectsWithGuidelines = (
  canvas: FabricCanvas,
): FabricObject[] => {
  const objects = canvas.getObjects().filter(({ guidelines }) => guidelines);
  return objects;
};

export const addGuidelinesToObject = (object: FabricObject): void => {
  object.guidelines = true;
};

const checkVerticalGuideline = (
  verticalGuideline: Guideline,
  activeObject: FabricObject,
  {
    verticalCenter,
    verticalLeft,
    verticalRight,
  }: Pick<EditorGuidelinesVisibility, EditorVerticalGuidelinesDirection>,
): void => {
  const { width } = activeObject.getBoundingRect(true);
  const activeObjectCenterX = activeObject.left;
  const activeObjectLeftX = activeObject.left - width / 2;
  const activeObjectRightX = activeObject.left + width / 2;

  const isLeftSideIntersect =
    verticalLeft && isVerticalIntersects(verticalGuideline, activeObjectLeftX);

  const isRightSideIntersect =
    verticalRight &&
    isVerticalIntersects(verticalGuideline, activeObjectRightX);

  const isCenterVerticalIntersect =
    verticalCenter &&
    isVerticalIntersects(verticalGuideline, activeObjectCenterX);

  if (isLeftSideIntersect)
    snapVerticalObject(activeObject, verticalGuideline.left);

  if (isRightSideIntersect)
    snapVerticalObject(activeObject, verticalGuideline.left - width);

  if (isCenterVerticalIntersect)
    snapVerticalObject(activeObject, verticalGuideline.left - width / 2);

  verticalGuideline.visible =
    isLeftSideIntersect || isRightSideIntersect || isCenterVerticalIntersect;
};

const checkHorizontalGuideline = (
  horizontalGuideline: Guideline,
  activeObject: FabricObject,
  {
    horizontalBottom,
    horizontalCenter,
    horizontalTop,
  }: Pick<EditorGuidelinesVisibility, EditorHorizontalGuidelinesDirection>,
): void => {
  const { height } = activeObject.getBoundingRect(true);
  const activeObjectCenterY = activeObject.top;
  const activeObjectBottomY = activeObject.top + height / 2;
  const activeObjectTopY = activeObject.top - height / 2;

  const isTopSideIntersect =
    horizontalTop &&
    isHorizontalIntersects(horizontalGuideline, activeObjectTopY);

  const isBottomSideIntersect =
    horizontalBottom &&
    isHorizontalIntersects(horizontalGuideline, activeObjectBottomY);

  const isCenterHorizontalIntersect =
    horizontalCenter &&
    isHorizontalIntersects(horizontalGuideline, activeObjectCenterY);

  if (isTopSideIntersect)
    snapHorizontalObject(activeObject, horizontalGuideline.top + height);

  if (isBottomSideIntersect)
    snapHorizontalObject(activeObject, horizontalGuideline.top);

  if (isCenterHorizontalIntersect)
    snapHorizontalObject(activeObject, horizontalGuideline.top + height / 2);

  horizontalGuideline.visible =
    isTopSideIntersect || isBottomSideIntersect || isCenterHorizontalIntersect;
};

export const checkGuidelinesIntersection = (
  guidelines: EditorGuidelines,
  activeObject: FabricObject,
  guidelinesVisibility: EditorGuidelinesVisibility = initialVisibleGuidelines,
): void => {
  const guidelinesEntries = Object.entries(guidelines) as [
    EditorHorizontalLinesNames | EditorVerticalLinesNames,
    any
  ][];
  guidelinesEntries.forEach(([ direction, guideline ]) => {
    const { horizontalLines, verticalLines } = getVisibleGuidelines(
      guidelinesVisibility,
    );
    if (verticalLines.includes(direction as EditorVerticalLinesNames))
      checkVerticalGuideline(guideline, activeObject, guidelinesVisibility);

    if (horizontalLines.includes(direction as EditorHorizontalLinesNames))
      checkHorizontalGuideline(guideline, activeObject, guidelinesVisibility);
  });
};

export const generateGuidelines = ({
  boundingRect,
  lineHeight,
  lineWidth,
}: {
  boundingRect: {
    height: number;
    left: number;
    top: number;
    width: number;
  };
  lineHeight: number;
  lineWidth: number;
}): DrawableGuidelines => {
  const verticalLineDots: LineDots = [ 0, 0, 0, lineHeight ];
  const horizontalLineDots: LineDots = [ 0, 0, lineWidth, 0 ];
  const { height, left, top, width } = boundingRect;
  const centerY = top + height / 2;
  const centerX = left + width / 2;
  const right = left + width;
  const bottom = top + height;

  return {
    horizontalBottom: {
      left: 0,
      lineDots: horizontalLineDots,
      top: bottom,
    },
    horizontalCenter: {
      left: 0,
      lineDots: horizontalLineDots,
      top: centerY,
    },
    horizontalTop: {
      left: 0,
      lineDots: horizontalLineDots,
      top,
    },
    verticalCenter: {
      left: centerX,
      lineDots: verticalLineDots,
      top: 0,
    },
    verticalLeft: {
      left,
      lineDots: verticalLineDots,
      top: 0,
    },
    verticalRight: {
      left: right,
      lineDots: verticalLineDots,
      top: 0,
    },
  };
};

export const createGuidelines = ({
  canvas,
  color = primaryViolet,
  dashed,
  guidelines,
}: {
  canvas: FabricCanvas;
  color?: string;
  dashed?: boolean;
  guidelines: DrawableGuidelines;
}): EditorGuidelines => {
  const strokeDashArray = dashed && [ 5, 5 ];

  const editorGuidelines = Object.entries(guidelines).map(
    ([ direction, { left, lineDots, top }]) => {
      const guideline = new fabric.Line(lineDots, {
        left,
        opacity: 1,
        stroke: color,
        strokeDashArray,
        top,
        visible: false,
      });
      canvas.add(guideline);
      return [ direction, guideline ];
    },
  );
  return Object.fromEntries(editorGuidelines);
};

export const removeGuidelines = (
  canvas: FabricCanvas,
  guidelines: EditorGuidelines,
): void => {
  Object.values(guidelines).forEach(line => canvas.remove(line));
};
