
import BaseAccordion from '@/components/Common/BaseAccordion/BaseAccordion.vue';
import BaseAccordionItem from '@/components/Common/BaseAccordion/BaseAccordionItem/BaseAccordionItem.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import BaseIcon from '@/components/Common/BaseIcon/BaseIcon.vue';
import { black87 } from '@/constants/colors';

export default {
  components: { BaseButton, BaseIcon, BaseAccordion, BaseAccordionItem },
  props: {
    horizontalAlignments: {
      required: true,
      type: Array,
    },
    verticalAlignments: {
      required: true,
      type: Array,
    },
  },
  emits:['triggerAlignment'],
  setup() {
    return {
      black87,
    };
  },
};
