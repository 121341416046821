
import useTextFontSize from './TextFontSize';
import TextFontSizeDesktop from './TextFontSizeDesktop/TextFontSizeDesktop.vue';
import TextFontSizeMobile from './TextFontSizeMobile/TextFontSizeMobile.vue';

export default {
  components: {
    TextFontSizeDesktop,
    TextFontSizeMobile,
  },
  props: {
    isMobile: {
      required: true,
      type: Boolean,
    },
    items: {
      required: false,
      type: Array,
    },
  },
  setup() {
    return useTextFontSize();
  },
};
