
import useChangeModeNoteMobile 
  from '@/components/Editor/ChangeModeNote/ChangeModeNoteMobile/ChangeModeNoteMobile';

export default {
  props: {
    isEditorActive: {
      required: true,
      type: Boolean,
    },
    noteVisible: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    return useChangeModeNoteMobile(context);
  },
};
