
import { computed, defineAsyncComponent } from 'vue';

import { UserRoles } from '@/constants/roles';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import store from '@/store';
import { GET_USER } from '@/store/Authorization/constants';

export default {
  components: {
    MainLayout,
  },
  setup() {
    const user = computed(() => store.getters[GET_USER]);

    const pages = {
      [UserRoles.Admin]: defineAsyncComponent(() => import('./AdminOrders/AdminOrders.vue')),
      [UserRoles.User]: defineAsyncComponent(() => import('./UserOrders/UserOrders.vue')),
    };

    const currentPage = computed(() => pages[user.value.role]);
    return {
      user,
      currentPage,
    };
  },
};
