import {
  DEFAULT_FRACTION_DIGITS,
} from '@/components/Editor/constants/defaultConfigs';
import { FabricObject } from '@/components/Editor/types/fabric';
import { roundToFixed } from '@/utils/math/roundToFixed';

export const validateActiveObjectProps = (object: FabricObject): void => {
  object.scaleX = roundToFixed(object.scaleX, DEFAULT_FRACTION_DIGITS);
  object.scaleY = roundToFixed(object.scaleY, DEFAULT_FRACTION_DIGITS);
};
