
import BackToTopButton from '@/components/Common/BackToTopButton/BackToTopButton.vue';
import BaseButton from '@/components/Common/BaseButton/BaseButton.vue';
import MainLayout from '@/layouts/MainLayout/MainLayout.vue';
import useUserGuide from './UserGuide';

export default {
  components: {
    BackToTopButton,
    BaseButton,
    MainLayout,
  },
  setup() {
    return useUserGuide();
  },
};
